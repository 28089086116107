<template>
	<v-list 
		color="rgb(0, 0, 0, 0)"
		expand
	>
		<v-list-group 
			color="rgb(0, 0, 0, 1)"
			:value="isMainMenuOpen"
		>
			<template v-slot:activator>
				<v-list-item-title class="font-weight-bold">Standard Selection</v-list-item-title>
			</template>

			<v-list-item
				v-for="(item, i) in mainMenuList"
				:key="i"
				link
				@click="selectMainMenu(i)"
			>
				<v-list-item-title
					:class="(selectedMenuItem == i) ? 'activated ml-3' : '' + ' ml-5'" 
					v-text="item.title"
				></v-list-item-title>
			</v-list-item>
		</v-list-group>

		<v-list-group 
			color="rgb(0, 0, 0, 1)"
			:value="isCustomizationMenuOpen"
			:append-icon="null"
			class="test"
			@click="() => {return null}"
		>
			<template v-slot:activator>
				<v-list-item-title 
					class="font-weight-bold" 
				>
					Customization By Part
				</v-list-item-title>
				<v-btn
					medium
					rounded
					depressed
					color="primary"
					class="secondary--text text-capitalize ml-3"
					@click="cancelZoomPart"
				>
					<span>Show Parts</span>
				</v-btn>
			</template>

			<v-list-item
				v-for="(item, i) in customizationMenu"
				v-show="isCustomizing"
				:key="i"
				link
				@click="selectCustomizationMenu(i)"
			>
				<v-list-item-title
					:class="(selectedCustomizationItem == i) ? 'activated ml-3' : '' + ' ml-5'"
					v-text="item.title"
				></v-list-item-title>
			</v-list-item>
			<v-list-item
				v-show="isCustomizing"
			>
				<v-btn
					medium
					rounded
					depressed
					color="primary"
					class="secondary--text text-capitalize ml-3"
					@click="$emit('next-socket', false)"
				>
					<span>Previous Socket</span>
				</v-btn>
				<v-btn
					medium
					rounded
					depressed
					color="primary"
					class="secondary--text text-capitalize ml-3"
					@click="$emit('next-socket', true)"
				>
					<span>Next Socket</span>
				</v-btn>
			</v-list-item>
		</v-list-group>
	</v-list>
</template>

<script>
import * as NAVBAR from '@/configs/navbar'
export default {
	components:{},
	props:{
		mainMenu:{
			type: Array,
			default: ()=>{ return [] }
		},
		customizationMenu:{
			type: Array,
			default: ()=>{ return [] }
		},
		selectedMenuItem: {
			type: Number,
			default: ()=>{ return null }
		},
		selectedCustomizationItem: {
			type: Number,
			default: ()=>{ return null }
		},
		isCustomizing: {
			type: Boolean,
			default: ()=>{ return false }
		}
	},
	data () {
		return {
			isMainMenuOpen:true,
			isCustomizationMenuOpen:false,
			mainMenuList: []
		}
	},
	watch:{
	},
	created(){
		this.mainMenuList = this.mainMenu
		this.mainMenuList.pop()
	},
	methods: {
		selectMainMenu(val)
		{
			this.$emit('select-main-menu', val)
		},
		selectCustomizationMenu(val)
		{
			this.$emit('select-customization-menu', val)
		},
		toggleCustomizationMenu(val)
		{
			this.isCustomizationMenuOpen = val
			this.isMainMenuOpen = !val
		},
		cancelZoomPart()
		{
			this.$emit('cancel-zoom-part')
		}
	}
}
</script>

<style scoped>
	.activated{
		color: #0ad8fc;
	}
</style>