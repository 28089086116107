<template>
	<v-dialog v-model="perforationDialog" persistent max-width="600px">
		<v-card>
			<div class="text-right pa-1">
				<v-btn icon @click="closeDialog()">
					<v-icon color="secondary">mdi-close</v-icon>
				</v-btn>
			</div>
			<v-container class="overflow-auto" style="max-height: 70vh;">
				<v-row>
					<div
						class="mx-auto my-auto font-weight-medium"
					>
						Perforation
					</div>
				</v-row>
				<v-row
					justify="center"
				>
					<template v-for="(item,i) in optionPerforations">
						<v-col
							:key="i"
							cols="4"
							v-if="item.file_uploads.length>=2"
						>
							<div
								v-ripple
								style="height: 70px;width: 70px;"
								:class="((formPerforation && formPerforation.id == item.id) ? 'highlight ' : '') + 'mx-auto my-auto'"
								@click="selectPerforation(item)" 
							>
								<v-img 
									:src="getImageFile(item)"
								></v-img>
							</div>
							<div class="body-2 text-center pt-1">{{ item.name }}</div>
						</v-col>
					</template>
				</v-row>
			</v-container>
			<div class="pa-4">
				<v-btn
					rounded
					depressed
					block
					color="primary"
					class="secondary--text text-capitalize"
					:disabled="!formPerforation"
					@click="emitSelected()"
				>
					Save
				</v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props:{
		perforationDialog:{
			type: Boolean,
			default: ()=>{ return false }
		},
		optionPerforations:{
			type: Array,
			default: ()=>{ return [] }
		},
		formPerforation:{
			type: Object,
			default: ()=>{ return null }
		},
		DEFAULT_IMAGE_FILE_TYPE_ID:{
			type: Number,
			default: ()=>{ return 0 }
		}
	},
	computed:
	{
	},
	data(){
		return {
		}
	},
	created(){
	},
	methods:{
		selectPerforation(item)
		{
			this.$emit('changeFormPerforation', item)
		},
		emitSelected(){
			this.$emit('updatePerforation', this.formPerforation)		
		},
		closeDialog()
		{
			this.$emit('closeDialog')
		},
		getImageFile(val)
		{
			val.image = val ? val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID).path : null
			return val.image
		},

	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>