<template>
	<div>
		<v-row dense justify="space-between">
			<template v-for="(seamItem,i) in seamSelections">
				<v-col
					:key="i"
					cols="12" md="2"
					v-if="allowedSeamSelectionId.includes(seamItem.value)"
				>
					<v-btn
						block depressed
						outlined rounded
						class="text-capitalize"
						:class="selectedSeam == seamItem.value ? 'secondary white--text' : '' "
						@click="selectSeam(seamItem.value)"
						v-text="seamItem.title"
					></v-btn>
				</v-col>
			</template>
			
			
			<ColorDialogPage
				:colorDialog="colorDialog"
				:optionColors="optionColors"
				:formColor="formColor"
				:dialogMode="dialogMode"

				@changeFormColor="changeFormColor"
				@updateSeamPipingColor="updateSeamPipingColor"
				@closeDialog="closeDialog"
			>
			</ColorDialogPage>
		</v-row>
		<v-row 
			class="pt-5" 
			align="center" 
			dense 
		>
			<v-col cols="auto">
				Selected Seam Color: 
			</v-col>
			<template v-if="selectedSeamColor">
				<v-col cols="auto" class="row align-center ma-0">
					<div
						style="height: 30px;width: 30px;"
						:style="'background:'+selectedSeamColor.rgba"
					>
					</div>
					<div class="pl-1">{{ selectedSeamColor.name }} {{ selectedSeamColor.description }}</div>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="auto" class="font-italic caption">No Color Selected</v-col>
			</template>
			<v-col cols="auto">
				<v-btn
					small
					rounded
					depressed
					color="primary"
					class="secondary--text text-capitalize"
					@click="changeColor(seamColors, 'seam')"
				>
					<span>Change</span>
				</v-btn>
			</v-col>
		</v-row>
		<v-row align="center" dense class="pt-3">
			<v-col cols="12">
				<v-btn
					small
					rounded
					depressed
					:color="pipingMode ? '#BBB' : 'primary'"
					class="secondary--text text-capitalize"
					@click="togglePipingMode()"
				>
					<span>{{ (pipingMode ? 'Disable' : 'Enable')+ ' Piping' }}</span>
				</v-btn>
			</v-col>
			<template v-if="pipingMode">
				<v-col cols="auto">
					Selected Piping Color: 
				</v-col>
				<template v-if="selectedPipingColor">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
							:style="'background:'+selectedPipingColor.rgba"
						></div>
						<div class="pl-1">{{ selectedPipingColor.name }} {{ selectedPipingColor.description }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Color Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changeColor(pipingColors, 'piping')"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</template>
		</v-row>
	</div>
	
</template>

<script>
import ColorDialogPage from "./ColorDialog"
import { mapGetters } from "vuex"

const DOUBLE_LINE_SEAM_ID = 1;
const SINGLE_LINE_SEAM_ID = 2;
const PLAIN_PIPING_ID = 3;
const SINGLE_LINE_PIPING_ID = 4;
const DOUBLE_LINE_PIPING_ID = 5;
const NO_SEAM_NO_PIPING = 6;
const HAS_PIPING = [SINGLE_LINE_PIPING_ID,DOUBLE_LINE_PIPING_ID,PLAIN_PIPING_ID]

export default {
	components:{
		ColorDialogPage,
	},
	props:{
		seamSelections:{
			type: Array,
			default: ()=>{ return [] }
		},
		form:{
			type: Object,
		},
		seamColors:{
			type: Array,
			default: ()=>{ return [] }
		},
		pipingColors:{
			type: Array,
			default: ()=>{ return [] }
		},
		DEFAULT_SEAM_COLOR_ID:{
			type: Number,
			default: ()=>{ return 0 }
		},
	},
	data(){
		return {
			colorDialog : false,
			pipingMode : false,
			dialogMode : 'seam',
			selectedSeam : 1,
			selectedSeamColor : null,
			selectedPipingColor: null,
			optionColors: [],
			formColor: null,
			allowedSeamSelectionId: [1, 2, 6],
		}
	},
	computed:
	{
		...mapGetters(["orderCustomizations"]),
	},
	watch:{
		'pipingColors':{
			handler(val){
				if(this.pipingMode)
				{
					this.updateSeamPipingColor('piping', this.pipingColors.find((item)=> item.id == this.pipingColors[0].id))
				}
			}
		}
	},
	created(){
	},
	mounted(){
		this.restoreCustomization()
	},
	methods:{
		updateSeamSelection()
		{
			var seamItem = null
			var seamId = this.selectedSeam

			if(this.pipingMode == true)
			{
				if(this.selectedSeam == SINGLE_LINE_SEAM_ID)
					seamId = SINGLE_LINE_PIPING_ID
				else if(this.selectedSeam == DOUBLE_LINE_SEAM_ID)
					seamId = DOUBLE_LINE_PIPING_ID
				else if(this.selectedSeam == NO_SEAM_NO_PIPING)	
					seamId = PLAIN_PIPING_ID
			}

			seamItem = this.seamSelections.find((item) => item.value == seamId)

			this.$emit('selectedSeam', seamItem)
		},
		changeColor(colors, mode){
			this.dialogMode = mode
			this.optionColors = colors

			if(mode == 'seam')
				this.formColor = this.selectedSeamColor
			else 
				this.formColor = this.selectedPipingColor

			this.colorDialog = true
		},
		changeFormColor(val)
		{
			this.formColor = val
		},
		updateSeamPipingColor(type, val)
		{
			if(type == 'seam')
				this.selectedSeamColor = val
			else
				this.selectedPipingColor = val

			this.$emit('selectColor', type, val)
			this.closeDialog()
		},
		closeDialog()
		{
			this.colorDialog = false
		},
		selectSeam(value)
		{
			this.selectedSeam = value

			this.updateSeamSelection()
		},
		togglePipingMode(){
			this.pipingMode = !this.pipingMode

			this.updateSeamSelection()
		},
		restoreCustomization()
		{
			if(this.orderCustomizations.seam)
			{
				let seam = this.orderCustomizations.seam

				if(HAS_PIPING.indexOf(seam.value) !== -1)
					this.pipingMode = true

				this.selectedSeam = seam.value

				if(seam.pipingColor)
					this.updateSeamPipingColor('piping', this.pipingColors.find((item)=> item.id == seam.pipingColor.id) ?? this.pipingColors.find((item)=> item.id == this.pipingColors[0].id))

				if(seam.seamColor)
				{
					this.updateSeamPipingColor('seam', this.seamColors.find((item)=> item.id == seam.seamColor.id))
				}

				this.updateSeamSelection()
			}
			else
			{
				this.updateSeamPipingColor('seam', this.seamColors.find((item)=> item.id == this.DEFAULT_SEAM_COLOR_ID))
				this.updateSeamPipingColor('piping', this.pipingColors.find((item)=> item.id == this.pipingColors[0].id))
			}
		}
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>