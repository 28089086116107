<template>
	<v-row dense>
		<v-col cois="12">
			<v-btn
				v-if="isZoom"
				small
				outlined
				color="error"
				class="text-capitalize"
				@click="cancelLogoableZoom()"
			>
				cancel Logo zoom part
			</v-btn>
			<div v-else>
				Please select the available part.
			</div>
		</v-col>
		<v-col cols="12">
			<v-row
				no-gutters 
				align="center"
				class="grey px-4 py-3 body-2"
			>
				<v-col>Logo File : {{ formLogo?(formLogo.name?formLogo.name:formLogo.code):'-' }}</v-col>
				<v-col cols="auto">
					<v-btn
						depressed
						small
						class="pa-1 white--text"
						color="red"
						@click="resetLogo()"
					>
						<v-icon>mdi-trash-can-outline</v-icon>
					</v-btn>
					<v-btn
						depressed
						small
						color="primary"
						class="text-capitalize secondary--text ml-1"
						@click="openLogoDialog()"
						:disabled="!enable"
					>
						Change Image
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<v-dialog v-model="logoDialog" persistent max-width="700px">
			<v-card
				style="overflow: hidden"
			>
				<div class="text-right pa-1">
					<v-btn icon @click="logoDialog = false">
						<v-icon color="secondary">mdi-close</v-icon>
					</v-btn>
				</div>
				<v-container class="pa-1 px-4">
					<v-select
						v-model="selected_type"
						:items="seatTypes"
						item-value="id"
						item-text="name"
						depressed
						block
						outlined
						@change="openPanel()"
					>
					</v-select>
				</v-container>
				<v-container class="pa-0 px-4">
					<v-text-field
						v-model="search_val"
						rounded
						depressed
						block
						dense
						outlined
						label="Search"
						:clearable="true"
						@input="search()"
						@click:clear="clear()"
					>
					</v-text-field>
				</v-container>
				<v-container
					ref="scrollField"
					v-scroll.self="onScroll"
					class="overflow-auto"
					style="max-height: 40vh;" 
				>
					<v-row 
						justify="center"
					>
						<v-col
							v-for="(logo,j) in seatTypes.find(i=> i.id == selected_type).logos"
							:key="j"
							col="auto"
						>
							<v-card 
								:class="((tempLogo && tempLogo.id == logo.id) ? 'highlight ' : '' ) + 'img-card mx-auto my-auto' "
								@click="selectLogo(logo)"
							>
								<v-img 
									:src="getSrc(logo, logo.file_uploads)"
									class="logo-img"
								>
								</v-img>
							</v-card>
							<div class="body-2 text-center pt-1">{{ logo.name }}</div>
						</v-col>
					</v-row>
				</v-container>
				<div class="pa-4">
					<v-btn
						rounded
						depressed
						block
						color="primary"
						class="secondary--text text-capitalize"
						:disabled="formLogo || tempLogo ? false : true"
						@click="emitSelected()"
					>
						Save
					</v-btn>
				</div>
			</v-card>
			<v-overlay
				:value="model_loading_"
				absolute
				style="z-index: 21;"
			>
				<v-progress-circular 
					indeterminate 
					size="96"
				>
					Loading...
				</v-progress-circular>
			</v-overlay>
		</v-dialog>
	</v-row>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"

export default {
	mixins: [
		resourceMixin,
		errorHandlerMixin
	],
	props:{
		isZoom:{
			type: Boolean,
			default: false,
		},
		seatLogoType:{
			type: Array,
			default: ()=>{return []},
		},
		form:{
			type: Object,
			default: ()=>{return {}},
		},
		enable:{
			type: Boolean,
			default: ()=>{return true},
		},
	},
	data(){
		return {
			logoDialog: false,
			formLogo: null,
			tempLogo: null,
			seatTypes: [],
			current_page: 0,
			openedLogoType: null,
			current_logo_type: null,
			search_val: null,
			selected_type: null
		}
	},
	created(){
		this.formLogo = this.form?.logo
		this.seatTypes = this.seatLogoType
		this.selected_type = this.seatTypes[0].id
		this.current_logo_type = this.seatTypes[0]
	},
	methods:{
		cancelLogoableZoom()
		{
			this.$emit('cancelLogoableZoom')
		},
		resetLogo()
		{
			this.formLogo = null
			this.$emit('resetLogo')
		},
		openLogoDialog()
		{
			this.tempLogo = this.formLogo
			this.logoDialog = true
		},
		getSrc(logo, files)
		{
			if(files)
			{
				let img = files.filter((file)=> file.mime_type == "image/png")
				if(img.length > 0)
				{
					logo.img = img[0].path
					return img[0].path
				}

				return null
			}

			return null
				
		},
		emitSelected()
		{
			this.formLogo = this.tempLogo
			this.$emit('select-logo', this.getSrc({}, this.formLogo ? this.formLogo.file_uploads : null), this.formLogo)
			this.logoDialog = false
		},
		selectLogo(logo)
		{
			if(this.tempLogo)
			{
				if(this.tempLogo.id == logo.id)
					this.tempLogo = null
				else
					this.tempLogo = logo
			}
			else
				this.tempLogo = logo
		},
		getResourceData_(reset = false)
		{
			let current_page = this.current_logo_type.current_page
			let payload = {
				itemsPerPage: 10,
				is_archived: 0,
				page: reset ? 1 : ++current_page,
				seat_logo_type_id: this.current_logo_type.id,
			}

			if(this.search_val)
				payload.name = this.search_val

			return this.$api.getSeatLogoList(payload).then(async (res)=>{
				let { data, total, next_page_url, current_page } = res.data.result
				this.current_logo_type.hasMorePage = !!next_page_url
				this.current_logo_type.current_page = current_page
				this.current_logo_type.logos = reset ? data : [...this.current_logo_type.logos, ...data]
				this.model_loading_ = false
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		onScroll(e){
			let scrollField = this.$refs['scrollField'];
			let scrollFieldHeight = scrollField.clientHeight
			let totalDataHeight = scrollField.scrollHeight
			let scrollY = e.target.scrollTop
			let isBottomOfPage = Math.ceil(scrollFieldHeight + scrollY) >= totalDataHeight
			let hasMorePage = this.seatTypes.find(i=> i.id == this.selected_type).hasMorePage
			if(hasMorePage && (isBottomOfPage) && this.model_loading_ == false){
				this.model_loading_ = true
				setTimeout(() => {
					this.getResourceData_()
				}, 1000 * 1);
			}
		},
		openPanel()
		{
			this.$refs['scrollField'].scrollTo(0,0)
			this.search_val = null
			this.current_logo_type = this.seatTypes.find(i=> i.id == this.selected_type)
			this.getResourceData_(true)
		},
		search()
		{
			this.getResourceData_(true)
		},
		clear()
		{
			this.search_val = null
			this.getResourceData_(true)
		}
	}
}
</script>


<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}

.img-card{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 140px;
	height: 140px;

}

.logo-img{
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: auto;
}
</style>