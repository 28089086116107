<template>
	<v-row dense>
		<v-col cois="12">
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize mr-3"
				@click="$emit('cancel-zoom-part')"
			>
				cancel zoom part
			</v-btn>
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize"
				@click="resetCustomization()"
			>
				Reset
			</v-btn>
		</v-col>
		<v-col cols="12">
			<v-row align="center" dense>
				<v-col cols="auto">
					Selected Special Material: 
				</v-col>
				<template v-if="selectedSpecialMaterial">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
						>
							<v-img :src="getImagePath(selectedSpecialMaterial)"></v-img>
						</div>
						<div class="pl-1">{{ selectedSpecialMaterial.name }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Special Material Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changeSpecialMaterial()"
						:disabled="enable"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<SpecialMaterialDialog
			:specialMaterialDialog="specialMaterialDialog"
			:optionSpecialMaterials="specialMaterials"
			:formSpecialMaterial="formSpecialMaterial"
			:selectedSpecialMaterial="selectedSpecialMaterial"
			:DEFAULT_IMAGE_FILE_TYPE_ID="DEFAULT_IMAGE_FILE_TYPE_ID"

			@changeFormSpecialMaterial="changeFormSpecialMaterial"
			@updateSpecialMaterial="updateSpecialMaterial"
			@closeDialog="closeDialog"
		>
		</SpecialMaterialDialog>
	</v-row>
</template>

<script>
import SpecialMaterialDialog from "./SpecialMaterialDialog"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;
const DEFAULT_MASK_FILE_TYPE_ID = 12;


export default {
	components:{
		SpecialMaterialDialog,
	},
	props:{
		specialMaterials:{
			type: Array,
			default: ()=>{ return [] }
		},
		partSpec: {
			type: Object,
			default: ()=>{ return {} }
		},
		enable: {
			type: Boolean,
			default: ()=>{ return false }
		}
	},
	watch: {
		"partSpec": {
			handler(){
				this.init()
			},
		},
	},
	computed:
	{
	},
	data(){
		return {
			specialMaterialDialog : false,
			selectedSpecialMaterial: null,
			formSpecialMaterial: null,
			DEFAULT_IMAGE_FILE_TYPE_ID: DEFAULT_IMAGE_FILE_TYPE_ID,
			DEFAULT_MASK_FILE_TYPE_ID: DEFAULT_MASK_FILE_TYPE_ID,
		}
	},
	created(){
		this.init()
	},
	methods:{
		init()
		{
			this.selectedSpecialMaterial = this.partSpec.special_material ?? null
		},
		changeSpecialMaterial(){
			this.formSpecialMaterial = this.selectedSpecialMaterial
			this.specialMaterialDialog = true
		},
		selectSpecialMaterial(val, mask_url)
		{
			this.$emit('select-special-material', val, mask_url)
		},
		changeFormSpecialMaterial(val)
		{
			this.formSpecialMaterial = val
		},
		updateSpecialMaterial(val)
		{
			this.selectedSpecialMaterial = val
			let temp = this.partSpec
			temp.special_material = val
			this.selectSpecialMaterial(temp, this.getMaskPath(val))
			this.closeDialog()
		},
		closeDialog()
		{
			this.specialMaterialDialog = false
		},
		getImagePath(val)
		{
			if(val)
			{
				val.image_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID).path

				return val.image_url
			}

			return null
		},
		getMaskPath(val)
		{
			if(val)
			{
				val.mask_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_MASK_FILE_TYPE_ID).path

				return val.mask_url
			}
			
			return null
		},
		resetCustomization()
		{
			this.$emit('reset-customization')
			this.selectedSpecialMaterial = null
		}
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>