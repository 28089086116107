<template>
	<v-dialog v-model="pspDialog" persistent max-width="600px">
		<v-card>
			<div class="text-right pa-1">
				<v-btn icon @click="closeDialog()">
					<v-icon color="secondary">mdi-close</v-icon>
				</v-btn>
			</div>
			<v-container
				ref="scrollField"
				class="overflow-auto" 
				style="max-height: 70vh;"
				v-scroll.self="onScroll"
			>
				<v-row>
					<div
						class="mx-auto my-auto font-weight-medium text-center"
					>
						Pattern Stitching + Digital Punching (PSP)
					</div>
				</v-row>
				<v-row
					class="mx-auto my-3 font-weight-medium"
					justify="center"
				>
					<template v-for="(item,i) in optionPsp">
						<v-col
							:key="i"
							cols="auto"
							v-if="item.file_uploads.length>=2"
						>
							<div
								v-ripple
								style="height: 70px;width: 70px;"
								:class="((formPsp && formPsp.id == item.id) ? 'highlight ' : '') + 'mx-auto my-auto'"
								@click="selectPsp(item)" 
							>
								<v-img 
									style="height: 70px;width: 70px;"
									:src="getImageFile(item)"
								></v-img>
							</div>
							<div class="body-2 text-center pt-1">{{ item.name }}</div>
						</v-col>
					</template>
				</v-row>
			</v-container>
			<div class="pa-4">
				<v-btn
					rounded
					depressed
					block
					color="primary"
					class="secondary--text text-capitalize"
					:disabled="!formPsp"
					@click="emitSelected()"
				>
					Save
				</v-btn>
			</div>
		</v-card>
		<v-overlay
			:value="model_loading_"
			absolute
			style="z-index: 21;"
		>
			<v-progress-circular 
				indeterminate 
				size="96"
			>
				Loading...
			</v-progress-circular>
		</v-overlay>
	</v-dialog>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
export default {
	mixins: [
		resourceMixin,
	],
	props:{
		pspDialog:{
			type: Boolean,
			default: ()=>{ return false }
		},
		formPsp:{
			type: Object,
			default: ()=>{ return null }
		},
		DEFAULT_IMAGE_FILE_TYPE_ID:{
			type: Number,
			default: ()=>{ return 0 }
		}
	},
	computed:
	{
	},
	data(){
		return {
			current_page: 0,
			hasMorePage: false,
			optionPsp: []

		}
	},
	created(){
		this.initialize_()
	},
	methods:{
		selectPsp(item)
		{
			this.$emit('changeFormPsp', item)
		},
		emitSelected(){
			this.$emit('updatePsp', this.formPsp)		
		},
		closeDialog()
		{
			this.$emit('closeDialog')
		},
		getImageFile(val)
		{
			val.image = val ? (val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID) ? val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID).path : null) : null
			return val.image
		},
		getResourceData_(reset = false)
		{
			let current_page = this.current_page
			let payload = {
				itemsPerPage: 25,
				is_archived: 0,
				page: reset ? 1 : ++current_page,
				type: 'psp',
			}

			return this.$api.getPerforationList(payload).then(async (res)=>{
				let { data, total, next_page_url, current_page } = res.data.result
				this.hasMorePage = !!next_page_url
				this.current_page = current_page
				this.optionPsp = reset ? data : [...this.optionPsp, ...data]
				this.model_loading_ = false
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		onScroll(e){
			let scrollField = this.$refs.scrollField;
			let scrollFieldHeight = scrollField.clientHeight
			let totalDataHeight = scrollField.scrollHeight
			let scrollY = e.target.scrollTop
			let isBottomOfPage = Math.ceil(scrollFieldHeight + scrollY) >= totalDataHeight
			if(this.hasMorePage && (isBottomOfPage) && this.model_loading_ == false){
				this.model_loading_ = true
				setTimeout(() => {
					this.getResourceData_()
				}, 1000 * 1);
			}
		},
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>