<template>
	<v-row dense>
		<v-col cois="12">
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize mr-3"
				@click="$emit('cancel-zoom-part')"
			>
				cancel zoom part
			</v-btn>
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize"
				@click="resetCustomization()"
			>
				Reset
			</v-btn>
		</v-col>
		<v-col cols="12">
			<v-row align="center" dense>
				<v-col cols="auto">
					Selected Pattern Stitching + Digital Punching (PSP): 
				</v-col>
				<template v-if="selectedPsp">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
						>
							<canvas id="pspWindow"></canvas>	
						</div>
						<div class="pl-1">{{ selectedPsp.name }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Pattern Stitching + Digital Punching (PSP) Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changePsp()"
						:disabled="enable"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" v-if="selectedPsp">
			<v-row align="center" dense>
				<v-col cols="auto">
					Selected Color: 
				</v-col>
				<template v-if="selectedColor">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
							:style="'background:'+selectedColor.rgba"
						></div>
						<div class="pl-1">{{ selectedColor.name }} {{ selectedColor.description }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Color Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changeColor()"
						:disabled="enable"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<PspDialog
			:pspDialog="pspDialog"
			:optionPsp="psps"
			:formPsp="formPsp"
			:selectedPsp="selectedPsp"
			:DEFAULT_IMAGE_FILE_TYPE_ID="DEFAULT_IMAGE_FILE_TYPE_ID"

			@changeFormPsp="changeFormPsp"
			@updatePsp="updatePsp"
			@closeDialog="closeDialog"
		>
		</PspDialog>
		<ColorDialogPage
			:colorDialog="colorDialog"
			:optionColors="colors"
			:formColor="formColor"
			:dialogMode="dialogMode"

			@changeFormColor="changeFormColor"
			@updateColor="updateColor"
			@closeDialog="closeColorDialog"
		>
		</ColorDialogPage>
	</v-row>
</template>

<script>
import PspDialog from "./PspDialog"
import ColorDialogPage from "./ColorDialog"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;
const DEFAULT_MASK_FILE_TYPE_ID = 12;
const DEFAULT_PSP_DESIGN_UPGRADE_COLOR = 31;


export default {
	components:{
		PspDialog,
		ColorDialogPage,
	},
	props:{
		psps:{
			type: Array,
			default: ()=>{ return [] }
		},
		colors:{
			type: Array,
			default: ()=>{ return [] }
		},
		partSpec: {
			type: Object,
			default: ()=>{ return {} }
		},
		enable: {
			type: Boolean,
			default: ()=>{ return false }
		}
	},
	watch: {
		"partSpec": {
			handler(){
				this.init()
			},
		},
	},
	computed:
	{
	},
	data(){
		return {
			pspDialog : false,
			colorDialog : false,
			dialogMode : 'digital_upgrade',
			selectedPsp: null,
			selectedColor: null,
			formPsp: null,
			formColor: null,
			DEFAULT_IMAGE_FILE_TYPE_ID: DEFAULT_IMAGE_FILE_TYPE_ID,
			DEFAULT_MASK_FILE_TYPE_ID: DEFAULT_MASK_FILE_TYPE_ID,
			DEFAULT_PSP_DESIGN_UPGRADE_COLOR: DEFAULT_PSP_DESIGN_UPGRADE_COLOR,
		}
	},
	created(){
		this.init()
	},
	methods:{
		init()
		{
			this.selectedPsp = this.partSpec.psp ? this.partSpec.psp.psp_details : null
			this.selectedColor = this.partSpec.psp ? this.partSpec.psp.color : null
			if(this.selectedPsp)
				this.loadSeamImage(this.selectedPsp.image, [this.selectedColor.r,this.selectedColor.g,this.selectedColor.b])
		},
		changePsp(){
			this.formPsp = this.selectedPsp
			this.pspDialog = true
		},
		changeFormPsp(val)
		{
			this.formPsp = val
		},
		updatePsp(val)
		{
			this.selectedPsp = val
			let temp = this.partSpec
			temp.psp = {}
			temp.psp.psp_details = val
			if(!this.selectedColor)
			{
				this.assignDefaultColor(temp.psp)
			}
			temp.psp.color = this.selectedColor
			let rgb = this.selectedColor ? [this.selectedColor.r,this.selectedColor.g,this.selectedColor.b] : []
			this.loadSeamImage(this.selectedPsp.image, rgb)
			this.$emit('select-psp', temp, this.getMaskPath(val), rgb)
			this.closeDialog()
			
		},
		closeDialog()
		{
			this.pspDialog = false
		},
		closeColorDialog()
		{
			this.colorDialog = false
		},
		getImagePath(val)
		{
			if(val)
			{
				val.image_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID).path

				return val.image_url
			}

			return null
		},
		getMaskPath(val)
		{
			if(val)
			{
				val.mask_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_MASK_FILE_TYPE_ID).path

				return val.mask_url
			}
			
			return null
		},
		resetCustomization()
		{
			this.$emit('reset-customization')
			this.selectedPsp = null
			this.selectedColor = null
		},
		changeColor(){
			this.formColor = this.selectedColor
			this.colorDialog = true
		},
		changeFormColor(val)
		{
			this.formColor = val
		},
		updateColor(val)
		{
			this.selectedColor = val
			let temp = this.partSpec
			let rgb = [val.r,val.g,val.b]
			temp.psp.color = val
			this.loadSeamImage(this.selectedPsp.image, rgb)
			this.$emit('select-psp', temp, this.getMaskPath(this.getDetails()), rgb)
			this.closeColorDialog()
		},
		assignDefaultColor(temp)
		{
			this.selectedColor = this.colors.find((item)=>item.id = this.DEFAULT_PSP_DESIGN_UPGRADE_COLOR) ?? null
			temp.color = this.selectedColor
		},
		getDetails()
		{
			return this.partSpec.psp.psp_details
		},
		loadSeamImage(url, rgba_seam = []){
			if(url)
			{
				var imageMask = new Image();
				imageMask.crossOrigin = "Anonymous";
				let _this = this
				
				imageMask.onload = function(){
					_this.drawImage(this, rgba_seam);
				};

				imageMask.src = url
			}	
			
		},
		drawImage(imageObj, rgba_seam){
			var canvas = document.getElementById("pspWindow");
			var context = canvas.getContext("2d");

			var destX = 0;
			var destY = 0;
			canvas.height = 101
			canvas.width = 101

			context.drawImage(imageObj, destX, destY, canvas.width, canvas.height);

			var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

			let seamColor = [230, 234, 237]

			if(rgba_seam.length>0)
				seamColor = rgba_seam

			for (var i = 0; i < imageData.data.length; i += 4) {
				if(imageData.data[i] >= 100 && imageData.data[i + 1] >=100 && imageData.data[i + 2] >= 100)
				{
					imageData.data[i] = seamColor[0];
					imageData.data[i + 1] = seamColor[1];
					imageData.data[i + 2] = seamColor[2];
				}
			}

			// overwrite original image
			context.putImageData(imageData, destX, destY);
		},
	}
}
</script>

<style scoped>
#pspWindow {
	width: 30px;
	height: 30px;
}
.highlight{
	border: 5px solid #fcde0a;
}
</style>