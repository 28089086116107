<template>
	<v-dialog v-model="colorDialog" persistent max-width="600px">
		<v-card>
			<div class="text-right pa-1">
				<v-btn icon @click="closeDialog()">
					<v-icon color="secondary">mdi-close</v-icon>
				</v-btn>
			</div>
			<v-container class="overflow-auto" style="max-height: 70vh;">
				<v-row
					justify="center"
				>
					<template v-for="(item,i) in optionColors">
						<v-col
							:key="i"
							cols="4"
							md="3"
						>
							<div
								v-ripple
								style="height: 70px;width: 70px;"
								:style="'background:'+item.rgba"
								:class="((formColor && formColor.id == item.id) ? 'highlight ' : '') + 'mx-auto my-auto'"
								@click="selectColor(item)" 
							></div>
							<div class="body-2 text-center pt-1">{{ item.name }}</div>
							<div class="body-2 text-center pt-1">{{ item.description }}</div>
						</v-col>
					</template>
				</v-row>
			</v-container>
			<div class="pa-4">
				<v-btn
					rounded
					depressed
					block
					color="primary"
					class="secondary--text text-capitalize"
					:disabled="!formColor"
					@click="emitSelected()"
				>
					Save
				</v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props:{
		colorDialog:{
			type: Boolean,
			default: ()=>{ return false }
		},
		optionColors:{
			type: Array,
			default: ()=>{ return [] }
		},
		formColor:{
			type: Object,
			default: ()=>{ return null }
		},
		dialogMode:{
			type: String,
			default: ()=>{ return null }
		}
	},
	computed:
	{
	},
	data(){
		return {
		}
	},
	created(){
	},
	methods:{
		selectColor(item)
		{
			this.$emit('changeFormColor', item)
		},
		emitSelected(){
			let selectedColor = this.formColor
			if(this.dialogMode == "secondary"){
				this.$emit('updateSecondaryColor', selectedColor)
			}else if(this.dialogMode == "main"){
				this.$emit('updateMainColor', selectedColor)		
			}
			else if(this.dialogMode == "seam" || this.dialogMode == "piping"){
				this.$emit('updateSeamPipingColor', this.dialogMode, selectedColor)		
			}
			else if(this.dialogMode == "digital_upgrade"){
				this.$emit('updateColor', selectedColor)		
			}
		},
		closeDialog()
		{
			this.$emit('closeDialog')
		}
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>