<template>
	<v-row dense justify="space-between">
		<v-col>
			<v-row align="center" dense>
				<v-col cols="auto">
					Selected Main Color: 
				</v-col>
				<template v-if="selectedMainColor">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
							:style="'background:'+selectedMainColor.rgba"
						></div>
						<div class="pl-1">{{ selectedMainColor.name }} {{ selectedMainColor.description }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Color Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changeColor(mainColors, 'main')"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</v-row>
			<v-row align="center" dense class="pt-3">
				<v-col cols="12">
					<v-btn
						small
						rounded
						depressed
						:color="secondaryMode ? '#BBB' : 'primary'"
						class="secondary--text text-capitalize"
						@click="toggleSecondaryMode()"
					>
						<span>{{ (secondaryMode ? 'Disable' : 'Enable')+ ' Secondary Color' }}</span>
					</v-btn>
				</v-col>
				<template v-if="secondaryMode">
					<v-col cols="auto">
						Selected Secondary Color: 
					</v-col>
					<template v-if="selectedSecondaryColor">
						<v-col cols="auto" class="row align-center ma-0">
							<div
								style="height: 30px;width: 30px;"
								:style="'background:'+selectedSecondaryColor.rgba"
							></div>
							<div class="pl-1">{{ selectedSecondaryColor.name }} {{ selectedSecondaryColor.description }}</div>
						</v-col>
					</template>
					<template v-else>
						<v-col cols="auto" class="font-italic caption">No Color Selected</v-col>
					</template>
					<v-col cols="auto">
						<v-btn
							small
							rounded
							depressed
							color="primary"
							class="secondary--text text-capitalize"
							@click="changeColor(secondaryColors, 'secondary')"
						>
							<span>Change</span>
						</v-btn>
					</v-col>
				</template>
			</v-row>
		</v-col>
		<ColorDialogPage
			:color-dialog="colorDialog"
			:option-colors="optionColors"
			:form-color="formColor"
			:dialog-mode="dialogMode"
			:selected-main-color="selectedMainColor"
			:selected-secondary-color="selectedSecondaryColor"

			@changeFormColor="changeFormColor"
			@updateSecondaryColor="updateSecondaryColor"
			@updateMainColor="updateMainColor"
			@closeDialog="closeDialog"
		>
		</ColorDialogPage>
	</v-row>
</template>

<script>
import ColorDialogPage from "./ColorDialog"
import { mapGetters } from "vuex"

export default {
	components:{
		ColorDialogPage,
	},
	props:{
		mainColors:{
			type: Array,
			default: ()=>{ return [] }
		},
		secondaryColors:{
			type: Array,
			default: ()=>{ return [] }
		},
		formMainColor:{
			type: Object,
			default: ()=>{ return {} }
		}
	},
	data(){
		return {
			colorDialog : false,
			dialogMode : null,
			secondaryMode: false,
			optionColors: [],
			selectedMainColor: null,
			selectedSecondaryColor: null,
			formColor: null,
		}
	},
	computed:
	{
		...mapGetters(["orderCustomizations"]),
	},
	created(){
		this.restoreCustomization()
		//this.selectMain(this.selectedMainColor)
	},
	methods:{
		changeColor(colors, mode){
			this.dialogMode = mode

			if(mode == 'main')
				this.formColor = this.selectedMainColor
			else 
				this.formColor = this.selectedSecondaryColor

			this.optionColors = colors
			this.colorDialog = true
		},
		toggleSecondaryMode(){
			this.secondaryMode = !this.secondaryMode
			if(this.secondaryMode == false){
				this.selectedSecondaryColor = null
				this.selectSecondary(null)
			}
		},
		selectMain(val)
		{
			this.$emit('select-main', val)
		},
		selectSecondary(val)
		{
			this.$emit('select-secondary', val)
		},
		changeFormColor(val)
		{
			this.formColor = val
		},
		updateMainColor(val)
		{
			this.selectedMainColor = val
			this.selectMain(val)
			this.closeDialog()
		},
		updateSecondaryColor(val)
		{
			this.selectedSecondaryColor = val
			this.selectSecondary(val)
			this.closeDialog()
		},
		closeDialog()
		{
			this.colorDialog = false
		},
		resetMainColor()
		{
			this.selectedMainColor = this.mainColors[0] ?? null
			this.selectMain(this.selectedMainColor)
		},
		restoreCustomization()
		{
			if(this.orderCustomizations.mainColor == this.formMainColor)
			{
				if(this.orderCustomizations.mainColor)
				{
					this.selectedMainColor = this.orderCustomizations.mainColor
					this.selectedMainColor.rgba = this.computedRGBA(this.selectedMainColor)
				}

				if(this.orderCustomizations.secondaryColor)
				{
					this.secondaryMode = true
					this.selectedSecondaryColor = this.orderCustomizations.secondaryColor
					this.selectedSecondaryColor.rgba = this.computedRGBA(this.selectedSecondaryColor)
				}
			}
			else
			{
				this.resetMainColor()
			}
		},
		computedRGBA(item){
			return `rgba(${item.r}, ${item.g}, ${item.b}, ${item.a});`
		},
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>