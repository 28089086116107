<template>
	<v-row dense>
		<v-col cois="12">
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize mr-3"
				@click="$emit('cancel-zoom-part')"
			>
				cancel zoom part
			</v-btn>
			<v-btn
				small
				outlined
				color="error"
				class="text-capitalize"
				@click="resetCustomization()"
			>
				Reset
			</v-btn>
		</v-col>
		<v-col cols="12">
			<v-row align="center" dense>
				<v-col cols="auto">
					Selected Digital Punching (PF): 
				</v-col>
				<template v-if="selectedDigitalPunching">
					<v-col cols="auto" class="row align-center ma-0">
						<div
							style="height: 30px;width: 30px;"
						>
							<v-img :src="getImagePath(selectedDigitalPunching)"></v-img>
						</div>
						<div class="pl-1">{{ selectedDigitalPunching.name }}</div>
					</v-col>
				</template>
				<template v-else>
					<v-col cols="auto" class="font-italic caption">No Digital Punching (PF) Selected</v-col>
				</template>
				<v-col cols="auto">
					<v-btn
						small
						rounded
						depressed
						color="primary"
						class="secondary--text text-capitalize"
						@click="changeDigitalPunching()"
						:disabled="enable"
					>
						<span>Change</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-col>
		<DigitalPunchingDialog
			:digitalPunchingDialog="digitalPunchingDialog"
			:optionDigitalPunchings="digitalPunchings"
			:formDigitalPunching="formDigitalPunching"
			:selectedDigitalPunching="selectedDigitalPunching"
			:DEFAULT_IMAGE_FILE_TYPE_ID="DEFAULT_IMAGE_FILE_TYPE_ID"

			@changeFormDigitalPunching="changeFormDigitalPunching"
			@updateDigitalPunching="updateDigitalPunching"
			@closeDialog="closeDialog"
		>
		</DigitalPunchingDialog>
	</v-row>
</template>

<script>
import DigitalPunchingDialog from "./DigitalPunchingDialog"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;
const DEFAULT_MASK_FILE_TYPE_ID = 12;


export default {
	components:{
		DigitalPunchingDialog,
	},
	props:{
		digitalPunchings:{
			type: Array,
			default: ()=>{ return [] }
		},
		partSpec: {
			type: Object,
			default: ()=>{ return {} }
		},
		enable: {
			type: Boolean,
			default: ()=>{ return false }
		},
	},
	watch: {
		"partSpec": {
			handler(){
				this.init()
			},
		},
	},
	computed:
	{
	},
	data(){
		return {
			digitalPunchingDialog : false,
			selectedDigitalPunching: null,
			formDigitalPunching: null,
			DEFAULT_IMAGE_FILE_TYPE_ID: DEFAULT_IMAGE_FILE_TYPE_ID,
			DEFAULT_MASK_FILE_TYPE_ID: DEFAULT_MASK_FILE_TYPE_ID,
		}
	},
	created(){
		this.init()
	},
	methods:{
		init()
		{
			this.selectedDigitalPunching = this.partSpec.digital_punching ? this.partSpec.digital_punching.digital_punching_details : null
		},
		changeDigitalPunching(){
			this.formDigitalPunching = this.selectedDigitalPunching
			this.digitalPunchingDialog = true
		},
		changeFormDigitalPunching(val)
		{
			this.formDigitalPunching = val
		},
		updateDigitalPunching(val)
		{
			this.selectedDigitalPunching = val
			let temp = this.partSpec
			temp.digital_punching = {}
			temp.digital_punching.digital_punching_details = val
			this.$emit('select-digital-punching', temp, this.getMaskPath(val))
			this.closeDialog()
		},
		closeDialog()
		{
			this.digitalPunchingDialog = false
		},
		closeColorDialog()
		{
			this.colorDialog = false
		},
		getImagePath(val)
		{
			if(val)
			{
				val.image_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_IMAGE_FILE_TYPE_ID).path

				return val.image_url
			}

			return null
		},
		getMaskPath(val)
		{
			if(val)
			{
				val.mask_url = val.file_uploads.find((item)=>item.file_upload_type_id == this.DEFAULT_MASK_FILE_TYPE_ID).path

				return val.mask_url
			}
			
			return null
		},
		resetCustomization()
		{
			this.$emit('reset-customization')
			this.selectedDigitalPunching = null
		},
		getDetails()
		{
			return this.partSpec.digital_punching.digital_punching_details
		}
	}
}
</script>

<style scoped>
.highlight{
	border: 5px solid #fcde0a;
}
</style>