<template>
	<div class="fill-height fill-width">
		<v-btn
			v-show="isCustomization"
			color="primary"
			class="checkout black--text font-weight-black rounded-0"
			@click="checkOut"
			elevation="0"
		>
			Check Out
		</v-btn>
		<v-container 
			fluid 
			class="pa-0" 
			style="height: calc(-60px + 100vh)" 
		>
			<div class="fill-height fill-width">
				<div style="height:95%;">
					<v-row
						v-show="!isCustomization"
						no-gutters
						class="title px-10 pt-10"
						style="position:absolute"
					>
						<v-col cols="12" md="auto" class="title mb-1">
							<span
								@click="$router.push('/new-order')"
							>
								Vehicle Information
							</span>
						</v-col>
						<v-col cols="12" md="auto" class="title mb-1 d-none d-sm-block">
							<span class="mx-2">
								<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
							</span>
							<span
								@click="$router.push('/new-order/select-seat')"
							>
								Select Seat Model
							</span>
						</v-col>
						<v-col cols="12" md="auto" class="title mb-1 d-none d-sm-block">
							<span class="mx-2">
								<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
							</span>
							<span @click="()=>{backToCustomizationPage()}">Customize Seat</span>
						</v-col>
						<v-col cols="12" md="auto" class="title mb-1 d-none d-sm-block">
							<span class="mx-2">
								<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
							</span>
							<span @click="()=>{$router.push('/new-order/order-options')}">Options</span>
						</v-col>
						<v-col cols="12" md="auto" class="title mb-1">
							<span class="mx-2">
								<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
							</span>
							<span @click="()=>{$router.push('/new-order/view-2d')}">View 2D</span>
						</v-col>
						<v-col cols="12" md="auto" class="title mb-1">
							<span class="mx-2">
								<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
							</span>
							<span class="black--text">View 3D</span>
						</v-col>
					</v-row>
					<SeatLoader
						ref="seatLoader"
						:is-zoom="isZoom"
						:item="selectedSeat"
						:part-specs="customizedPartSpecs"
						@seat-ready="()=>{
							isLoadedSeat = true;
						}"
						@click-socket="zoomToPart()"
						@add-part-spec="initPartSpec"
						@cancel-zoom="cancelZoom"
					></SeatLoader>
					<v-row
						v-show="!isCustomization"
						justify="end"
						class="pr-5"
					>
						<v-col 
							cols="11" md="3"
							style="position: fixed; bottom: 20px; right: 15px"
						>
							<v-btn
								v-if="$authUser.isGuest()"
								block
								depressed
								color="primary"
								class="text-capitalize secondary--text radius-10"
								@click="logoutAndRedirect"
							>
								Register An Account
							</v-btn>
							<v-btn
								v-else
								block
								depressed
								color="primary"
								class="text-capitalize secondary--text radius-10"
								@click="()=>{$router.push('/new-order/order-summary')}"
							>
								View Order Summary
							</v-btn>
						</v-col>
					</v-row>
				</div>
				<div
					v-if="$vuetify.breakpoint.mdAndUp"
					v-show="isCustomization"
					class="absolute pa-4"
					style="top: 0px;"
				>
					<div class="title">{{ selectedSeatModel.model_name }}</div>
				</div>
				<div
					v-show="isCustomization && !hide"
					class="absolute pa-4"
					style="top: 0px;"
				>
					<div 
						v-if="$vuetify.breakpoint.mdAndUp"
						class="pa-3 mt-3"
						style="width: 100%"
					>
						<NavMenu
							ref="navMenu"
							:main-menu="getMainMenu()"
							:customization-menu="getCustomizationMenu()"
							:selected-menu-item="slider"
							:selected-customization-item="sliderCustomization"
							:is-customizing="isCustomizingPart"

							@select-main-menu="selectMenu"
							@select-customization-menu="selectCustomizationMenu"
							@cancel-zoom-part="showParts"
							@next-socket="nextSocket"
						></NavMenu>
					</div>
				</div>
				<div
					v-if="$authUser.isCustomer() || $authUser.isShowroom()"
					v-show="isCustomization && !isZoom"
					class="absolute text-right pa-4"
					style="top: 0px;right: 120px;"
				>
					<span class="font-weight-black">Price List</span>
				</div>
				<div
					v-show="isZoom && $vuetify.breakpoint.smAndDown"
					class="absolute text-right pa-4"
					style="top: 20px;left: 0px;"
				>
					<v-btn 
						text 
						class="font-weight-black"
						@click="cancelZoom"
					>Back</v-btn>
				</div>
				<div
					class="absolute text-right pa-4 pt-10"
					style="top: 0px;right: 0px;"
				>
					<div
						v-show="isCustomization"
						:style="'display: flex; visibility: ' + (!isZoom ? 'visible;' : 'hidden;')" 
						class="secondary--text body-1 font-weight-bold cursor-pointer unselectable"
					>
						<div
							v-ripple
							class="px-2 py-1 mr-3"
							:style="'border: 1px solid; border-radius: 15px  15px  15px 15px; visibility: ' + (($authUser.isCustomer() || $authUser.isShowroom()) && !isZoom ? 'visible;' : 'hidden;')" 
							@click="togglePrice()"
						>
							<v-btn 
								:class="showPrice ? 'float-left' : 'float-right'"
								icon 
								small 
								:style="'background:' + (showPrice?'green':'red')"
							>
							</v-btn>
							<span class="float-right mx-2 justify-center">{{ showPrice ? 'On' : 'Off' }}</span>
						</div>

						<div
							v-ripple
							class="px-4 py-1 pr-3"
							style="border: 1px solid; border-radius: 15px 0 0 15px;"
							@click="openPDF()"
						>
							2D
						</div>
						<div
							v-ripple
							class="primary px-4 py-1 pl-3"
							style="border: 1px solid !important; border-radius: 0 15px 15px 0;"
						>
							3D
						</div>
					</div>
					<div>
						<div class="mt-3">
							<v-btn 
								v-if="hasSocket" 
								icon 
								large 
								@click="slider == UPLOAD_LOGO_PAGE_INDEX ? toggleLogoAbleSocketBtn() : toggleSocketBtn()"
							>
								<v-icon color="secondary">{{ socketVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}</v-icon>
							</v-btn>
							<v-btn 
								v-if="$vuetify.breakpoint.mdAndUp"
								icon large @click="getSeatLoader().zoomCamera(-5)"
							>
								<v-icon color="secondary">mdi-magnify-plus-outline</v-icon>
							</v-btn>
						</div>
						<div
							v-if="$vuetify.breakpoint.mdAndUp"
						>
							<v-btn icon large @click="getSeatLoader().zoomCamera(5)">
								<v-icon color="secondary">mdi-magnify-minus-outline</v-icon>
							</v-btn>
						</div>
					</div>

					<div
						v-if="enableColorPick"
						v-show="isCustomizingPart"
						v-ripple
						class="white ml-auto mt-2"
						style="border: 1px solid !important; border-radius: 15px 15px 15px 15px; width: 28%;"
					>
						<div style="width: 50%;" class="px-1 py-1">
							<v-btn 
								icon 
								large 
								:style="'background:'+ selectedMainColor().rgba"
								:class="((selectedPartSpec().color.id == selectedMainColor().id) ? 'highlight ' : '')"
								@click="updatePartColor('Main',selectedMainColor())"
							>
							</v-btn>
						</div>
						<div style="width: 50%;" class="px-1 py-1">
							<v-btn 
								icon 
								large 
								:style="'background:'+ selectedSecondaryColor().rgba"
								:class="((selectedPartSpec().color.id == selectedSecondaryColor().id) ? 'highlight ' : '')"
								@click="updatePartColor('Secondary',selectedSecondaryColor())"
							>
							</v-btn>
						</div>
					</div>
				</div>
				<div 
					v-if="($authUser.isCustomer() || $authUser.isShowroom()) && showPrice"
					v-show="isCustomization"
					class="absolute text-right pa-4"
					style="top: 85px;right: 0;"
				>
					<PriceDetail
						:selection-counter="selectionCounter"
						:price-info="price"
						:loading="loading_price"
					></PriceDetail>
				</div>
				<div
					v-show="isCustomization"
					class="absolute fill-width"
					style="bottom: 0px; max-height: 100vh;"
				>	
					<div
						v-show="slider == SEAM_SELECTION_INDEX"
						class="absolute fill-width"
						:style="'top: -300px; right: ' +( $vuetify.breakpoint.xsOnly ? '35vw' : '100px') + '; width: 201px; height: 201px;' "
					>
						<canvas id="seamWindow"></canvas>				
					</div>
					<v-card class="white" style="border-radius: 25px 25px 0 0;">
						<v-row
							no-gutters
						>	
							<v-col cols="12">
								<v-row no-gutters align="center" class="pa-4">
									<v-col cols="auto">
										<v-btn
											v-if="slider != CUSTOMIZATION_BY_PART_INDEX"
											depressed
											x-small fab
											color="secondary"
											@click="help_dialog = true"
										>
											<v-icon color="primary">mdi-help</v-icon>
										</v-btn>
										<!-- <v-btn icon @click="checkOut()">
											<v-icon>mdi-camera</v-icon>
										</v-btn> -->
									</v-col>
									<!-- Main slider -->
									<v-col v-show="!isCustomizingPart">
										<v-row
											no-gutters
											justify="center" align="center"
											class="px-4"
										>
											<v-col cols="auto">
												<v-btn icon @click="slider == 0?slider=4:slider--">
													<v-icon>mdi-chevron-left</v-icon>
												</v-btn>
											</v-col>
											<v-col md="3">
												<v-carousel
													v-model="slider"
													hide-delimiters
													height="auto"
													light
													:show-arrows="false"
												>
													<v-carousel-item
														v-for="(menu, i) in getMainMenu()"
														:key="i"
													>
														<div
															class="text-center"
															:class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1 font-weight-bold' "
														>
															{{ menu.title }}
														</div>
													</v-carousel-item>
												</v-carousel>
											</v-col>
											<v-col cols="auto">
												<v-btn icon @click="slider++">
													<v-icon>mdi-chevron-right</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-col>
									<!-- Customization by part slider -->
									<v-col v-if="isCustomizingPart">
										<v-row
											no-gutters
											justify="center" align="center"
											class="px-4"
										>
											<v-col cols="auto">
												<v-btn icon @click="sliderCustomization == 0?sliderCustomization=5:sliderCustomization--">
													<v-icon>mdi-chevron-left</v-icon>
												</v-btn>
											</v-col>
											<v-col md="3">
												<v-carousel
													v-model="sliderCustomization"
													hide-delimiters
													height="auto"
													light
													:show-arrows="false"
												>
													<v-carousel-item
														v-for="(menu, i) in getCustomizationMenu()"
														:key="i"
													>
														<div
															class="text-center"
															:class="$vuetify.breakpoint.mdAndUp ? 'title' : 'body-1 font-weight-bold' "
														>
															{{ menu.title }}
														</div>
													</v-carousel-item>
												</v-carousel>
											</v-col>
											<v-col cols="auto">
												<v-btn icon @click="sliderCustomization++">
													<v-icon>mdi-chevron-right</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-col>

									<v-col cols="auto">
										<v-btn icon @click="toggleBottomMenu()">
											<v-icon color="secondary" large>{{ miniBottomMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
							<v-col v-show="!miniBottomMenu" cols="12">
								<!-- Main window -->
								<v-window v-show="!isCustomizingPart && isLogoZoom" v-model="slider">
									<v-window-item>
										<div class="pa-5">
											<v-row dense justify="space-between">
												<template v-for="(packageItem,i) in packageSelections">
													<v-col
														:key="i"
														cols="12" lg="4"
													>
														<!-- <v-btn small @click="toggleInspector()">toggleInspector</v-btn> -->
														<v-btn
															block depressed
															outlined rounded
															class="text-capitalize"
															:class="form.package == packageItem.title ? 'secondary white--text' : '' "
															@click="selectedPackage(packageItem)"
															style="white-space: initial;"
														>
															{{ packageItem.title + '' }}
															<span 
																v-if="packageItem.price" 
																class="ml-2 blue--text"
															>{{ (packageItem.currency ? packageItem.currency : "MYR")  + ' '+ packageItem.price }}</span>
														</v-btn>
													</v-col>
												</template>
											</v-row>
										</div>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<ColorSelection
												ref="colorSelection"
												:main-colors="option_main_colors"
												:secondary-colors="option_secondary_colors"
												:form-main-color="form.mainColor"

												@select-main="selectMainColor"
												@select-secondary="selectSecondaryColor"
											></ColorSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<SeamSelection
												ref="SeamSelection"
												:seam-selections="option_seam_selections"
												:DEFAULT_SEAM_COLOR_ID="DEFAULT_SEAM_COLOR_ID"
												:seam-colors="option_seam_color"
												:piping-colors="option_main_colors"
												:form="form"

												@selectedSeam="selectedSeam"
												@selectColor="selectSeamColor"
											></SeamSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<LogoSelection
												:is-zoom="isZoom"
												:seat-logo-type="seat_logo_type"
												:form="form"
												:enable="enableLogo"

												@cancelLogoableZoom="cancelLogoableZoom"
												@select-logo="selectLogo"
												@resetLogo="resetLogo"
											>
											</LogoSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
									</v-window-item>
								</v-window>
								<!-- Customzation window -->
								<v-window v-if="isCustomizingPart" v-model="sliderCustomization">
									<v-window-item>
										<v-container class="pb-5">
											<v-col>
												<v-row>
													<v-btn
														small
														outlined
														color="error"
														class="text-capitalize"
														@click="showParts()"
													>
														cancel zoom part
													</v-btn>
												</v-row>
												<v-row dense justify="center" class="mt-5">
													<v-btn
														depressed
														outlined rounded
														class="text-capitalize"
														v-text="selectedPart() ? selectedPart().socketInfo.material_value : ''"
													></v-btn>
												</v-row>
											</v-col>
											
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<SpecialMaterialSelection
												ref="specialMaterialSelection"
												:special-materials="option_special_materials"
												:part-spec="selectedPartSpec()"
												:enable="enableDesignUpgrade()"

												@select-special-material="selectSpecialMaterial"
												@update-part-spec="updatePartSpec"
												@reset-customization="resetCustomization"
												@cancel-zoom-part="showParts"
											></SpecialMaterialSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<PspSelection
												ref="pspSelection"
												:psps="option_psps"
												:part-spec="selectedPartSpec()"
												:colors="option_digital_upgrade_colors"
												:enable="enablePsp()"

												@select-psp="selectPsp"
												@update-part-spec="updatePartSpec"
												@reset-customization="resetCustomization"
												@cancel-zoom-part="showParts"
											></PspSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<PatternStitchingSelection
												ref="patternStitchingSelection"
												:pattern-stitchings="option_pattern_stitchings"
												:part-spec="selectedPartSpec()"
												:colors="option_seam_color"
												:enable="enableDesignUpgrade()"

												@select-pattern-stitching="selectPatternStitching"
												@update-part-spec="updatePartSpec"
												@reset-customization="resetCustomization"
												@cancel-zoom-part="showParts"
											></PatternStitchingSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<PerforationSelection
												ref="perforationSelection"
												:perforations="option_perforations"
												:part-spec="selectedPartSpec()"
												:enable="enablePerforation()"

												@select-perforation="selectPerforation"
												@update-part-spec="updatePartSpec"
												@reset-customization="resetCustomization"
												@cancel-zoom-part="showParts"
											></PerforationSelection>
										</v-container>
									</v-window-item>
									<v-window-item>
										<v-container class="pb-5">
											<DigitalPunchingSelection
												ref="digitalPunchingSelection"
												:digital-punchings="option_digital_punchings"
												:part-spec="selectedPartSpec()"
												:colors="option_digital_upgrade_colors"
												:enable="enableDesignUpgrade()"

												@select-digital-punching="selectDigitalPunching"
												@update-part-spec="updatePartSpec"
												@reset-customization="resetCustomization"
												@cancel-zoom-part="showParts"
											></DigitalPunchingSelection>
										</v-container>
									</v-window-item>
								</v-window>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</div>
			<v-bottom-sheet v-model="bottomSheet" persistent>
				<v-card>
					<v-row>
						<v-col cols="auto">
							<v-icon color="transparent">mdi-pen</v-icon>
						</v-col>
						<v-col>
							<div class="text-center title">Package Selection</div>
						</v-col>
						<v-col cols="auto">
							<v-btn icon @click="bottomSheet = false">
								<v-icon color="secondary">mdi-close</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="12">
							<template v-for="(item,i) in packageSelections">
								<div :key="i">{{ item.title }}</div>
							</template>
						</v-col>
					</v-row>
				</v-card>
			</v-bottom-sheet>

			<v-dialog v-model="help_dialog" max-width="500px" persistent>
				<InfoCard 
					:contents="[
						{ title: 'Package Selection', text: 'Choose material type. EX: Leather and non leather'},
						{ title: 'Color', text: 'Choose main and secondary color'},
						{ title: 'Seam Selection', text: 'Choose seam design'},
						{ title: 'Upload Logo', text: 'Choose from a variety of logo'},
					]"
					@close="help_dialog = false"
				></InfoCard>
			</v-dialog>
		</v-container>
		
		<v-overlay
			:value="!isLoadedSeat || !isLoadedSeam || !isSelectPackage"
			style="z-index: 21;"
		>
			<v-progress-circular 
				indeterminate 
				size="96"
			>
				Loading...
			</v-progress-circular>
		</v-overlay>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import SeatLoader from "@/components/SeatLoader"
import NavMenu from "@/components/NavMenu"
import ColorSelection from "./SelectionMenu/ColorSelection"
import LogoSelection from "./SelectionMenu/LogoSelection"
import SeamSelection from "./SelectionMenu/SeamSelection"
import SpecialMaterialSelection from "./SelectionMenu/SpecialMaterialSelection"
import PerforationSelection from "./SelectionMenu/PerforationSelection"
import PspSelection from "./SelectionMenu/PspSelection"
import PatternStitchingSelection from "./SelectionMenu/PatternStitchingSelection"
import DigitalPunchingSelection from "./SelectionMenu/DigitalPunchingSelection"
import PriceDetail from "./PriceDetail"
import InfoCard from "./InfoCard"
import Vue from 'vue'
import { mapGetters, mapActions } from "vuex"

// file type
const FILE_TYPE_PDF = 2
// package type
const PACKAGE_FULL_LEATHER = 2
const PACKAGE_HALF_LEATHER = 4
const PACKAGE_QUARTER_LEATHER = 8
const PACKAGE_FULL_PVC = 15
const PACKAGE_FULLY_SYNTHETIC = 35
const PACKAGE_NAPPA_LEATHER = 46
// color group
const COLOR_GROUP_MAIN = "1"
const COLOR_GROUP_SECONDARY = "2"
const COLOR_GROUP_MAIN_SECONDARY = "3"
const COLOR_GROUP_SEAM = "4"
const COLOR_GROUP_DIGITAL_UPGRADE = "6"

// package type
const PACKAGE_SELECTION_INDEX = 0
const SEAM_SELECTION_INDEX = 2
const UPLOAD_LOGO_PAGE_INDEX = 3
const CUSTOMIZATION_BY_PART_INDEX = 4

const SEAM_FILE_UPLOAD_TYPE_ID = 11

//DEFAULT
const DEFAULT_LOGO_SEAM_COLOR = 376
const DEFAULT_PSP_COLOR = 377
const DEFAULT_MAIN_COLOR_ID = 229
const DEFAULT_SEAM_COLOR_ID = 323

export default {
	components:{
		SeatLoader,
		ColorSelection,
		LogoSelection,
		InfoCard,
		SeamSelection,
		SpecialMaterialSelection,
		PerforationSelection,
		PspSelection,
		PatternStitchingSelection,
		DigitalPunchingSelection,
		NavMenu,
		PriceDetail,
	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin,

	],
	data(){
		return {
			help_dialog: false,
			bottomSheet: false,
			miniBottomMenu: false,
			slider: null,
			sliderCustomization: null,
			option_seam_selections: [],
			packageSelections: [
				{ value: PACKAGE_FULL_LEATHER,  title: "Full Leather", },
				{ value: PACKAGE_HALF_LEATHER,  title: "Half Leather", },
				{ value: PACKAGE_QUARTER_LEATHER,  title: "Quarter Leather", },
				{ value: PACKAGE_FULL_PVC,  title: "Full PVC", },
				{ value: PACKAGE_FULLY_SYNTHETIC,  title: "Full Synthetic", },
				{ value: PACKAGE_NAPPA_LEATHER,  title: "Full Nappa Leather", },
				
			],
			form:{
				order_id: null,
				package: "Full Leather",
				package_id: null,
				seam: {
					value: 1,
					seamColor: {
						id: 0
					},
					pipingColor: {
						id: 0
					}
				},
				logo: null,
				logo_partspec: null,
				logoSeamColor: null,
				mainColor: {
					id: null
				},
				secondaryColor: null,
				pspColor: null,
				driverSeat: {
					id: null,
				},
				component: null,
				remark: null,
				screenshot: null,
				estimatedTimeDelivery: null,
				paymentMethod: null,
				shipmentMethod: null,
				deliveryType: 'new',
				billingAddressType: 'new',
				deliveryAddress: {
					deliveryAddress:  null,
					deliveryAddress2: null,
					deliveryCity:  null,
					deliveryPostcode:  null,
					deliveryState:  null,
					deliveryCountry:  null,
				},
				billingAddress: {
					billingAddress:  null,
					billingAddress2: null,
					billingCity:  null,
					billingPostcode:  null,
					billingState:  null,
					billingCountry:  null,
				},
				orderQty: 1,
				purchaseOrder: null,
			},
			option_main_colors: [],
			option_secondary_colors: [],
			option_seam_color: [],
			option_digital_upgrade_colors: [],
			option_logo_seam_colors: [],
			option_special_materials: [],
			option_perforations: [],
			option_psps: [],
			option_pattern_stitchings: [],
			option_digital_punchings: [],
			isLoadedSeat: false,
			isSelectPackage: false,
			isLoadedSeam: false,
			isSubmittingOrder: false,
			isZoom: false,
			seat_logo_type: [],
			customizedPartSpecs: [],
			enableColorPick: false,
			showPrice: false,
			price: {},
			selectionCounter: {
				seat: {
					label: null
				},
				main_color: {
					label: 'Main Color',
					counter: 0 
				},
				secondary_color: {
					label: 'Secondary Color',
					counter: 0 
				},
				seam: {
					label: 'Seam',
					counter: 0 
				},
				logo: {
					label: 'Logo',
					counter: 0 
				},
				special_material: {
					label: 'Special Material',
					counter: 0 
				},
				perforation: {
					label: 'Perforation',
					counter: 0 
				},
				psp: {
					label: 'PSP',
					counter: 0 
				},
				pd: {
					label: 'Digital Punching',
					counter: 0 
				},
				pf: {
					label: 'Pattern Stitching',
					counter: 0 
				},
			},
			loading_price: false,
			hide: false,
			socketVisible: false
		}
	},
	computed: {
		...mapGetters(["isCustomization", "selectedSeat", "selectedSeatModel", "orderCustomizations", "partSpecs", "orderOptions"]),
		isCustomizingPart()
		{
			if(this.isZoom && this.slider == CUSTOMIZATION_BY_PART_INDEX)
			{
				return true
			}

			return false
		},
		hasSocket()
		{
			if(this.slider == CUSTOMIZATION_BY_PART_INDEX || this.slider == UPLOAD_LOGO_PAGE_INDEX)
				return true

			return false
		},
		isLogoZoom()
		{
			if(this.slider == UPLOAD_LOGO_PAGE_INDEX && !this.isZoom)
				return false

			return true
		},
		enableLogo()
		{
			if(this.isZoom)
			{
				if(this.selectedPartSpec().psp || this.selectedPartSpec().perforation || this.selectedPartSpec().special_material || this.selectedPartSpec().digital_punching || this.selectedPartSpec().pattern_stitching)
					return false
			}

			return true
		},
	},
	watch:{
		'slider':{
			handler(val){
				this.$refs.seatLoader.cameraAttachControl()
				if(val == UPLOAD_LOGO_PAGE_INDEX){
					this.cancelZoomPart()
					this.toggleSocketBtn(false)
					this.toggleLogoAbleSocketBtn(true)
				}
				else if(val == SEAM_SELECTION_INDEX)
				{
					this.cancelZoomPart()
					this.toggleLogoAbleSocketBtn(false)
					this.toggleSocketBtn(false)
					this.getSeatLoader().disabledLogoMode()
					this.$refs.seatLoader.cameraDetachControl()
				}
				else if(val == CUSTOMIZATION_BY_PART_INDEX){
					this.cancelZoomPart()
					this.toggleLogoAbleSocketBtn(false)
					this.toggleSocketBtn(true)
					this.getSeatLoader().disabledLogoMode()
				}
				else{
					this.cancelZoomPart()
					this.toggleLogoAbleSocketBtn(false)
					this.toggleSocketBtn(false)
					this.getSeatLoader().disabledLogoMode()
				}
			}
		},
		'isCustomizingPart':{
			handler(val){
				if(val)
				{
					this.miniBottomMenu = false
				}
			}
		},
		'isLogoZoom': {
			handler(val){
				if(val)
				{
					this.miniBottomMenu = false
				}
			}
		}
	},
	activated(){
		if(Object.keys(this.orderCustomizations).length === 0)
			this.backToCustomizationPage()
	},
	created(){
		if(this.$vuetify.breakpoint.smAndDown)
			this.miniBottomMenu = true

		if(this.selectedSeatModel == null){
			this.$router.push({ name: 'SelectSeatPage'})
		}
		this.getMainSecondaryColor()
		this.getSeamColor()
		this.getDigitalUpgradeColor()
		this.getSeam()
		this.getLogoType()
		this.getSpecialMaterial()
		this.getPerforation()
		this.getPsp()
		this.getPatternStitching()
		this.getDigitalPunching()
		this.getEstimatedTimeDelivery()
		
		if(this.$authUser.isCustomer() || this.$authUser.isShowroom())
		{
			this.getPackagePrice()
		}

		//window.addEventListener('beforeunload', this.beforeLeaving)

		this.CUSTOMIZATION_BY_PART_INDEX = CUSTOMIZATION_BY_PART_INDEX
		this.SEAM_SELECTION_INDEX = SEAM_SELECTION_INDEX
		this.UPLOAD_LOGO_PAGE_INDEX = UPLOAD_LOGO_PAGE_INDEX

		this.DEFAULT_MAIN_COLOR_ID = DEFAULT_MAIN_COLOR_ID
		this.DEFAULT_SEAM_COLOR_ID = DEFAULT_SEAM_COLOR_ID
		this.DEFAULT_LOGO_SEAM_COLOR = DEFAULT_LOGO_SEAM_COLOR
		this.DEFAULT_PSP_COLOR = DEFAULT_PSP_COLOR
	},
	beforeDestroy()
	{
		//window.removeEventListener("beforeunload", this.beforeLeaving)
	},
	mounted(){
		this.restoreCustomization()
		this.getSeatLoader().loadChair( this.selectedSeatModel.glbSeat.path.replace(process.env.VUE_APP_BUCKET_DOMAIN, '') )
	},
	methods:{
		...mapActions(["setOrderCustomizations", "setPartSpecs", "setOrderOptions", "setIsCustomization", "setPriceInfo", "setSelectionCounter", "setRedirect"]),
		initPartSpec(item)
		{
			if(this.customizedPartSpecs.length == 0)
				this.customizedPartSpecs = item
		},
		getSeatLoader(){
			return this.$refs.seatLoader
		},
		getColorSelection(){
			return this.$refs.colorSelection
		},
		getSeamSelection(){
			return this.$refs.SeamSelection
		},
		getSpecialMaterialSelection(){
			return this.$refs.specialMaterialSelection
		},
		getPerforationSelection(){
			return this.$refs.perforationSelection
		},
		getPspSelection(){
			return this.$refs.pspSelection
		},
		getPatternStitchingSelection(){
			return this.$refs.patternStitchingSelection
		},
		getDigitalPunchingSelection(){
			return this.$refs.digitalPunchingSelection
		},
		getNavMenu(){
			return this.$refs.navMenu
		},
		getPdfPreview(val)
		{
			this.pdfPreview = this.selectedSeatModel.file_uploads.find((item)=> item.file_upload_type_id == FILE_TYPE_PDF && item.package_id == val)
		},
		openPDF(){
			if(this.pdfPreview){
				let fileUrl = this.pdfPreview.pdf_reader_friendly_path
				window.open(fileUrl, '_BLANK')
			}
			else
			{
				this.$toast.warning('2D Preview is currently unavailable for this package.')
			}
		},
		async renderPackage(){
			await this.getSeatLoader().renderPackage()
		},
		async selectedPackage(val){
			this.isSelectPackage = false
			this.getPdfPreview(val.value)
			this.form.package = val.title
			this.form.package_id = val.value
			await this.reset()
			let relatedSockets = this.selectedSeatModel.sd_socket.filter((item)=> item.package_id == val.value)
			this.getSeatLoader().setPackageSockets(relatedSockets)
			await this.renderPackage()
			this.getOrderPrice()
			this.selectionCounter.seat.label = this.selectedSeatModel.seater.id + ' Seater ' + val.title
		},
		selectedSeam(val, seamColor = null, seamPiping = null){
			this.form.seam = val
			this.form.seam.seamColor = this.selectedSeamColor() ?? seamColor
			this.form.seam.pipingColor = this.selectedPipingColor() ?? seamPiping
			this.loadSeamImage('/' + val.title + '.png', this.form.seam.seamColor, this.form.seam.pipingColor)
			this.editCounter('seam', true)
		},
		selectSeamColor(type, val){
			if(type == "seam")
				this.form.seam.seamColor = val
			else
				this.form.seam.pipingColor = val
			
			this.loadSeamImage('/' + this.form.seam.title + '.png', this.form.seam.seamColor, this.form.seam.pipingColor)
		},
		getMainSecondaryColor(){
			return new Promise(resolve => {
				let payload = {
					package_name: this.form.package == 'Others' ? '' : this.form.package,
					itemsPerPage: 100,
					is_archived: 0,
				}
				this.$api.getColorList(payload).then(async (res)=>{
					let { data } = res.data.result
					data.forEach((item)=>{
						item.rgba = this.computedRGBA(item)
					})
					this.option_main_colors = await data.filter((item)=> item.type_id == COLOR_GROUP_MAIN || item.type_id == COLOR_GROUP_MAIN_SECONDARY)
					this.option_secondary_colors = data.filter((item)=> item.type_id == COLOR_GROUP_SECONDARY || item.type_id == COLOR_GROUP_MAIN_SECONDARY)
					await this.defaultMainColor()
					this.selectMainColor(this.form.mainColor)
					if(this.getColorSelection())
					{
						this.getSeatLoader().setSecondaryColor()
						this.getColorSelection().secondaryMode = false
						this.getColorSelection().resetMainColor()
						this.getColorSelection().selectedSecondaryColor = null
					}
					
					this.isSelectPackage = true
					resolve(true)
				}).catch((err)=>{
					this.errorHandler_(err)
					resolve(true)
				})
			})
		},
		getSeamColor(){
			let payload = {
				type_id: COLOR_GROUP_SEAM,
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getColorList(payload).then((res)=>{
				let { data } = res.data.result
				data.forEach((item)=>{
					item.rgba = this.computedRGBA(item)
				})
				this.option_seam_color = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getDigitalUpgradeColor(){
			let payload = {
				type_id: COLOR_GROUP_DIGITAL_UPGRADE,
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getColorList(payload).then((res)=>{
				let { data } = res.data.result
				data.forEach((item)=>{
					item.rgba = this.computedRGBA(item)
				})
				this.option_digital_upgrade_colors = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getSeam()
		{
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getSeamList(payload).then((res)=>{
				let { data } = res.data.result
				data.forEach((item)=>{
					let seam = { 
						value: item.id,  
						title: item.name,
						image: item.file_uploads.length>0?item.file_uploads.filter((item)=> item.file_upload_type_id == SEAM_FILE_UPLOAD_TYPE_ID)[0].path:'',
					}

					this.option_seam_selections.push(seam)
				})
				if(!this.form.seam.title)
					this.selectedSeam(this.option_seam_selections[0])
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getLogoType()
		{
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getSeatLogoTypeList(payload).then((res)=>{
				let { data } = res.data.result
				data.forEach(async (item)=>
				{
					let page = 1
					let result = null
					item.logos = []
					result = await this.getLogoList(item.id, page)
					item.hasMorePage = result.hasMorePage
					item.current_page = result.current_page
					item.logos = item.logos.concat(result.data)
					// do
					// {
					// 	result = await this.getLogoList(item.id, page)
					// 	item.logos = item.logos.concat(result.data)
					// 	page++
					// }while(page <= result.last_page)
				})
				this.seat_logo_type = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getLogoList(type_id, page = 1)
		{
			if(type_id)
			{
				let payload = {
					itemsPerPage: 10,
					is_archived: 0,
					page: page,
					seat_logo_type_id: type_id,
				}

				return this.$api.getSeatLogoList(payload).then(async (res)=>{
					let { result } = res.data
					result.hasMorePage = !!result.next_page_url
					return result
				}).catch((err)=>{
					this.errorHandler_(err)
				})
			}
		},
		getSpecialMaterial(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getSpecialMaterialList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_special_materials = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPerforation(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
				type: 'normal_perforation',
			}
			this.$api.getPerforationList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_perforations = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPsp(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
				type: 'psp',
			}
			this.$api.getPerforationList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_psps = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getPatternStitching(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
				type: 'pd',
			}
			this.$api.getDesignUpgradeList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_pattern_stitchings = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getDigitalPunching(){
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
				type: 'pf',
			}
			this.$api.getDesignUpgradeList(payload).then((res)=>{
				let { data } = res.data.result
				this.option_digital_punchings = data
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		getEstimatedTimeDelivery()
		{
			let payload = {
				itemsPerPage: 100,
				is_archived: 0,
			}
			this.$api.getETD(payload).then((res)=>{
				let { result } = res.data
				this.form.estimatedTimeDelivery = result
			}).catch((err)=>{
				this.errorHandler_(err)
			})
			
		},
		computedRGBA(item){
			return `rgba(${item.r}, ${item.g}, ${item.b}, ${item.a});`
		},
		toggleBottomMenu(){
			this.miniBottomMenu = !this.miniBottomMenu
		},
		toggleLogoAbleSocketBtn(isVisible = null){
			this.socketVisible = isVisible ?? !this.socketVisible
			let seatLoader = this.getSeatLoader()
			let logoableBtns = seatLoader.getLogoableSocketBtns()
			seatLoader.setSocketBtnsVisible(logoableBtns, isVisible)
		},
		toggleSocketBtn(isVisible = null){
			this.socketVisible = isVisible ?? !this.socketVisible
			let seatLoader = this.getSeatLoader()
			let allSocketBtns = seatLoader.getSocketBtns()
			seatLoader.setSocketBtnsVisible(allSocketBtns, isVisible)
		},
		getMainMenu(){
			return [
				{ title: "Package Selection", },
				{ title: "Color Selection", },
				{ title: "Seam Selection", },
				{ title: "Upload Logo", },
				{ title: "Customization by part", },
			]
		},
		getCustomizationMenu(){
			let menu = [
				{ title: "Material Type", },
				{ title: "Special Material", },
				{ title: "Pattern Stitching + Digital Punching (PSP)", },
				{ title: "Pattern Stitching (PD)", },
				{ title: "Perforation", },
				{ title: "Digital Punching (PF)", },
			]

			if(this.form.package_id == PACKAGE_FULL_PVC || this.form.package_id == PACKAGE_FULLY_SYNTHETIC)
			{
				let index = menu.findIndex(item => item.title == "Perforation")
				menu.splice(index, 1)

				index = menu.findIndex(item => item.title == "Digital Punching (PF)")
				menu.splice(index, 1)
			}

			return menu;
		},
		cancelLogoableZoom(){
			let seatLoader = this.getSeatLoader()
			seatLoader.cameraAttachControl()
			seatLoader.resetCameraPosition()
			seatLoader.removeClickOutsideEvent()
			this.toggleLogoAbleSocketBtn(true)
			this.isZoom = false
			this.getSeatLoader().disabledLogoMode()
		},
		cancelZoomPart(){
			this.getNavMenu()?.toggleCustomizationMenu(false)
			let seatLoader = this.getSeatLoader()
			seatLoader.cameraAttachControl()
			seatLoader.resetCameraPosition()
			seatLoader.removeClickOutsideEvent()
			let allSocketBtns = seatLoader.getSocketBtns()
			seatLoader.setSocketBtnsVisible(allSocketBtns, true)
			this.isZoom = false
			this.enableColorPick = false
		},
		zoomToPart(){
			let seatLoader = this.getSeatLoader()
			if(this.slider == UPLOAD_LOGO_PAGE_INDEX)
				this.toggleLogoAbleSocketBtn()
			else
				this.toggleSocketBtn()
			//Remove camera control after zooming in
			//seatLoader.cameraDetachControl()
			//let allSocketBtns = seatLoader.getSocketBtns()
			//seatLoader.setSocketBtnsVisible(allSocketBtns, false)
			this.isZoom = true
			this.showPrice = false
			if(this.slider == UPLOAD_LOGO_PAGE_INDEX){
				this.getSeatLoader().enableLogoMode()
			}
			else
			{
				if(this.hasSecondaryColor())
					this.enableColorPick = true
				else
					this.enableColorPick = false

				this.getNavMenu()?.toggleCustomizationMenu(true)
			}
			this.sliderCustomization = null
		},
		resetLogo(){
			this.form.logo = null
			this.form.logo_partspec = null
			this.getSeatLoader().removeLogoDecal()
			this.editCounter('logo', false)
		},
		takeScreenshot(){
			this.selectMenu(PACKAGE_SELECTION_INDEX)
			let image = this.getSeatLoader().takeScreenshot()
			this.form.screenshot = image
			this.form.screenshotFile = this.dataURLtoFile(image)

			this.customizedPartSpecs.forEach((item) => {
				if(item.part && item.part.relatedSocketBtn)
				{
					image = this.getSeatLoader().takeScreenshot(item)
					item.image = image
					item.imageFile = this.dataURLtoFile(image)
				}
			})
			
		},
		loadSeamImage(url, rgba_seam = '', rgba_piping = ''){
			if(url)
			{
				this.isLoadedSeam = false
				var imageMask = new Image();
				imageMask.crossOrigin = "Anonymous";
				let _this = this
				
				imageMask.onload = function(){
					_this.drawImage(this, rgba_seam, rgba_piping);
					_this.isLoadedSeam = true
				};

				imageMask.src = url
			}
		},
		drawImage(imageObj, rgba_seam, rgba_piping){
			var canvas = document.getElementById("seamWindow");
			var context = canvas.getContext("2d");

			var destX = 0;
			var destY = 0;
			canvas.height = 301
			canvas.width = 301

			context.drawImage(imageObj, destX, destY, canvas.width, canvas.height);

			var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

			let seamColor = [230, 234, 237]
			let pipingColor = [230, 234, 237]

			if(rgba_seam)
				seamColor = [rgba_seam.r, rgba_seam.g, rgba_seam.b]

			if(rgba_piping)
				pipingColor = [rgba_piping.r, rgba_piping.g, rgba_piping.b]

			for (var i = 0; i < imageData.data.length; i += 4) {
				if(imageData.data[i] >= 0 && imageData.data[i] <= 100 && imageData.data[i + 1] >=100 && imageData.data[i + 2] >= 0 && imageData.data[i + 2] <= 100)
				{
					imageData.data[i] = seamColor[0];
					imageData.data[i + 1] = seamColor[1];
					imageData.data[i + 2] = seamColor[2];
				}
				else if(imageData.data[i] >= 140 && imageData.data[i + 1] >= 0 && imageData.data[i + 2] >= 0)
				{
					imageData.data[i] = pipingColor[0];
					imageData.data[i + 1] = pipingColor[1];
					imageData.data[i + 2] = pipingColor[2];
				}
			}

			// overwrite original image
			context.putImageData(imageData, destX, destY);
		},
		async reset()
		{
			await this.resetPartSpecs()
			await this.getMainSecondaryColor()
		},
		async selectMainColor(val)
		{
			this.form.mainColor = val
			for(let part_spec of this.customizedPartSpecs)
			{
				part_spec.color = val
				this.updatePartSpec(part_spec)
			}
			await this.getSeatLoader().setMainColor(val)

			this.editCounter('main_color', true)
		},	
		selectSecondaryColor(val)
		{
			this.form.secondaryColor = val
			this.getSeatLoader().setSecondaryColor(val)
			if(val)
				this.editCounter('secondary_color', true)
			else
				this.editCounter('secondary_color', false)
		},	
		async selectSpecialMaterial(val, mask_url)
		{
			this.resetPerforation(val)
			this.resetPsp(val)
			this.updatePartSpec(val)
			await this.getSeatLoader().applySpecialMaterial(mask_url, val.part ?? null)
		},
		async selectPerforation(val, mask_url, selectedMesh = null)
		{
			return new Promise((resolve, reject) => {
				this.resetSpecialMaterial(val)
				this.resetPatternStitching(val)
				this.resetDigitalPunching(val)
				this.resetPsp(val)
				this.updatePartSpec(val)
				resolve(this.getSeatLoader().applyPerforation(mask_url, [], selectedMesh))
			})
		},
		async selectPsp(val, mask_url, color=[], selectedMesh = null)
		{
			return new Promise((resolve, reject) => {
				this.resetSpecialMaterial(val)
				this.resetPerforation(val)
				this.resetPatternStitching(val)
				this.resetDigitalPunching(val)
				this.updatePartSpec(val)
				resolve(this.getSeatLoader().applyPerforation(mask_url, color, selectedMesh))
			})
		},
		async selectPatternStitching(val, mask_url,color=[], selectedMesh = null)
		{
			this.resetPerforation(val)
			this.resetPsp(val)
			this.resetDigitalPunching(val)
			this.updatePartSpec(val)
			await this.getSeatLoader().applyPerforation(mask_url, color, selectedMesh)
		},
		async selectDigitalPunching(val, mask_url, color=[], selectedMesh = null)
		{
			this.resetPerforation(val)
			this.resetPsp(val)
			this.resetPatternStitching(val)
			this.updatePartSpec(val)
			await this.getSeatLoader().applyPerforation(mask_url, color, selectedMesh)
		},
		selectLogo(val, selectedLogo, selectedMesh = null, selected_part = this.selectedPartSpec())
		{
			this.resetLogo()
			this.form.logo = selectedLogo
			this.form.logo_partspec = selected_part.material_no_web
			this.getSeatLoader().setLogoMaterial(val, selectedMesh)
			this.editCounter('logo', true)
		},
		selectedPart()
		{
			return this.getSeatLoader() ? this.getSeatLoader().selectedMesh : null
		},
		selectedMainColor()
		{
			return this.getColorSelection() ? this.getColorSelection().selectedMainColor : null
		},
		selectedSecondaryColor()
		{
			return this.getColorSelection() ? this.getColorSelection().selectedSecondaryColor : null
		},
		selectedSeamColor()
		{
			return this.getSeamSelection() ? this.getSeamSelection().selectedSeamColor : null
		},
		selectedPipingColor()
		{
			return this.getSeamSelection() ? this.getSeamSelection().selectedPipingColor : null
		},
		selectedPartSpec()
		{
			let selected_part = this.customizedPartSpecs.find((item) => item.material_no_web == this.selectedPart().socketInfo.material_no_web)
			return selected_part
		},
		updatePartSpec(val)
		{
			const index = this.customizedPartSpecs.findIndex((item) => item.material_no_web === val.material_no_web)
			Vue.set(this.customizedPartSpecs, index, val)
			this.getOrderPrice()
		
		},
		updatePartColor(colorType, color)
		{
			let temp = this.selectedPartSpec()
			temp.color = color
			this.updatePartSpec(temp)
			if(!temp.special_material)
			{
				this.getSeatLoader().setPartColor(color)
			}
		},
		resetCustomization()
		{
			let temp = this.selectedPartSpec()
			this.resetSpecialMaterial(temp)
			this.resetPatternStitching(temp)
			this.resetDigitalPunching(temp)
			this.resetPerforation(temp)
			this.resetPsp(temp)
			
			this.updatePartSpec(temp)
			this.getSeatLoader().resetCustomization()
		},
		resetSpecialMaterial(temp)
		{
			this.$delete(temp, 'special_material')
			this.getSpecialMaterialSelection() ? this.getSpecialMaterialSelection().selectedSpecialMaterial = null : ''
		},
		resetPatternStitching(temp)
		{
			this.$delete(temp, 'pattern_stitching')
			this.getPatternStitchingSelection() ? this.getPatternStitchingSelection().selectedPatternStitching = null : ''
		},
		resetDigitalPunching(temp)
		{
			this.$delete(temp, 'digital_punching')
			this.getDigitalPunchingSelection() ? this.getDigitalPunchingSelection().selectedDigitalPunching = null : ''
		},
		resetPerforation(temp)
		{
			this.$delete(temp, 'perforation')
			this.getPerforationSelection() ? this.getPerforationSelection().selectedPerforation = null : null
		},
		resetPsp(temp)
		{
			this.$delete(temp, 'psp')
			this.getPspSelection() ? this.getPspSelection().selectedPsp = null : ''
		},
		enableDesignUpgrade()
		{
			if(this.selectedPartSpec().perforation || this.selectedPartSpec().psp || this.selectedPartSpec().material_no_web == this.form.logo_partspec)
				return true

			return false
		},
		enablePerforation()
		{
			if(this.selectedPartSpec().special_material || this.selectedPartSpec().digital_punching || this.selectedPartSpec().pattern_stitching || this.selectedPartSpec().material_no_web == this.form.logo_partspec)
				return true

			return false
		},
		enablePsp()
		{
			if(this.selectedPartSpec().special_material || this.selectedPartSpec().digital_punching || this.selectedPartSpec().pattern_stitching || this.selectedPartSpec().material_no_web == this.form.logo_partspec)
				return true

			return false
		},
		hasSecondaryColor()
		{
			if(this.selectedMainColor() != null && this.selectedSecondaryColor() != null)
			{
				return true
			}

			return false
		},
		getLogoImage(logo, files)
		{
			if(files)
			{
				let img = files.filter((file)=> file.mime_type == "image/png")
				if(img.length > 0)
				{
					logo.img = img[0].path
					return img[0].path
				}

				return null
			}

			return null
				
		},
		selectCustomizationMenu(val)
		{	
			this.sliderCustomization = val
		},
		selectMenu(val)
		{
			this.cancelZoomPart()
			this.cancelLogoableZoom()
			this.toggleSocketBtn(false)
			this.getSeatLoader().disabledLogoMode()
			if(val != UPLOAD_LOGO_PAGE_INDEX)
				this.toggleLogoAbleSocketBtn(false)
			else
				this.toggleLogoAbleSocketBtn(true)
			this.slider = val
		},
		showParts()
		{
			this.cancelLogoableZoom()
			this.getSeatLoader().disabledLogoMode()
			this.cancelZoomPart()
			this.slider = CUSTOMIZATION_BY_PART_INDEX
		},
		checkOut()
		{
			this.getAllRefs_([
				this.takeScreenshot(),
				this.getSeamImage()
			]).then(()=>{
				this.saveUserCustomizations()
			}).then(()=>{
				this.$router.push("/new-order/order-options")
			})			
		},
		defaultMainColor()
		{
			if(this.option_main_colors.length>0)
			{
				this.form.mainColor = this.option_main_colors[0]
			}
		},
		getSeamImage()
		{
			var canvas = document.getElementById("seamWindow");

			this.form.seam.image = canvas.toDataURL('image/png')
				

			if(!this.form.seam.seamColor)
			{
				this.form.seam.seamColor = this.option_seam_color[0]
			}
		},
		getOrderPayload()
		{
			let sd = this.selectedSeatModel
			let form = this.form
			let payload = {
				sd_id: sd.id,
				package_id: form.package_id,
				main_color_id: form.mainColor.id ?? DEFAULT_MAIN_COLOR_ID,
				seam_id: form.seam.value,
				seam_color_id: form.seam.seamColor ? form.seam.seamColor.id : 0,
				piping_color_id: form.seam.pipingColor ? form.seam.pipingColor.id : 0,
				secondary_color_id: form.secondaryColor ? form.secondaryColor.id : 0,
				logo_id: form.logo ? form.logo.id : 0,
				logo_color_id: form.logoSeamColor ? form.logoSeamColor.id : 0,
				driver_seat_id: form.driverSeat.id,
				component_id: form.component ? form.component.id : 0,
				qty: form.orderQty,
				dealer_remark: form.remark,
				purchase_order: form.purchaseOrder,
				delivery_address: {
					delivery_address: null,
					delivery_address2: null,
					delivery_city: null,
					delivery_postcode: null,
					delivery_state: null,
					delivery_country: null,
				},
				billing_address: {},
				shipping_method_id: form.shipmentMethod,
				payment_method_id: form.paymentMethod ?? 0,
			}

			payload.part_specs = this.getPartSpecPayload()

			return payload
		},
		getPartSpecPayload()
		{
			let payload = []
			let form = this.form
			let special_material_counter = 0
			let perforation_counter = 0
			let psp_counter = 0
			let pattern_stitching_counter = 0
			let digital_punching_counter = 0
			for(let part_spec of this.customizedPartSpecs)
			{
				let item = {
					material_no: part_spec.material_no,
					color_id: part_spec.color ? part_spec.color.id : form.mainColor.id ,
					perforation: 0,
					perforation_color_id: 0,
					design_upgrade: 0,
					design_upgrade_color_id: 0,
					special_material: 0,
				}

				if(part_spec.special_material)
				{
					item.special_material = part_spec.special_material.name
					special_material_counter += 1
				}

				if(part_spec.perforation)
				{
					item.perforation = part_spec.perforation.name
					perforation_counter += 1
				}
				else if(part_spec.psp)
				{
					item.perforation = part_spec.psp.psp_details.name
					item.perforation_color_id = part_spec.psp.color.id
					psp_counter += 1
				}

				if(part_spec.pattern_stitching)
				{
					item.design_upgrade = part_spec.pattern_stitching.pattern_stitching_details.name
					item.design_upgrade_color_id = part_spec.pattern_stitching.color.id
					pattern_stitching_counter += 1
				}
				else if(part_spec.digital_punching)
				{
					item.design_upgrade = part_spec.digital_punching.digital_punching_details.name
					digital_punching_counter += 1
				}

				payload.push(item)
			}

			this.selectionCounter.special_material.counter = special_material_counter
			this.selectionCounter.perforation.counter = perforation_counter
			this.selectionCounter.psp.counter = psp_counter
			this.selectionCounter.pd.counter = digital_punching_counter
			this.selectionCounter.pf.counter = pattern_stitching_counter

			return payload
		},
		dataURLtoFile(dataurl, filename = 'image') 
		{
			var arr = dataurl.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), 
				n = bstr.length, 
				u8arr = new Uint8Array(n);
				
			while(n--){
				u8arr[n] = bstr.charCodeAt(n);
			}
			
			return new File([u8arr], filename, {type:mime});
		},
		beforeLeaving(event)
		{
			event.preventDefault()
			event.returnValue = ""
		},
		togglePrice()
		{
			this.showPrice = !this.showPrice
		},
		getOrderPrice()
		{
			this.loading_price = true
			let order_payload = this.getOrderPayload()

			this.$api.getOrderPrice(order_payload).then((res)=>{
				let { result } = res.data
				this.price = result
				this.setPriceInfo(this.price)
				this.loading_price = false
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		editCounter(type, isIncrease)
		{
			if(type == 'logo' || type == 'main_color' || type == 'secondary_color' || type == 'seam')
			{
				if(isIncrease)
					this.selectionCounter[type].counter = 1
				else
					this.selectionCounter[type].counter = 0
			}
			
		},
		getPackagePrice()
		{
			let sd_id = this.selectedSeatModel.id
			let payload = {
				sd_id: sd_id,
				price_type_id: 2,
				currency_by_ip: true,
			}

			this.$api.getCoverPrice(payload).then((res)=>{
				let { data } = res.data.result
				if(data.length > 0)
				{
					this.assignPackagePrice(data)
				}
			}).catch((err)=>{
				this.errorHandler_(err)
			})
		},
		assignPackagePrice(packages)
		{
			for(let package_type of this.packageSelections)
			{
				let package_info = packages.find(item=> item.package_id == package_type.value && item.price_type_id == 2)
				package_type.price = package_info ? package_info.price : null 
				package_type.currency = package_info.currency ? package_info.currency.iso_code : "MYR" 
			}
		},
		saveUserCustomizations()
		{
			this.setOrderCustomizations(this.form)
			this.setPartSpecs(this.customizedPartSpecs)
			this.setSelectionCounter(this.selectionCounter)
		},
		backToCustomizationPage()
		{
			this.setIsCustomization(true)
		},
		logoutAndRedirect()
		{
			this.setRedirect(true)
			localStorage.setItem('isCustomizating', true)
			for(let partSpec of this.partSpecs)
			{
				partSpec.part = null
				partSpec.image = null
				partSpec.imageFile = null
				localStorage.setItem('partSpecs_' + partSpec.material_no, JSON.stringify(partSpec))
			}
			this.orderCustomizations.screenshot = null
			this.orderCustomizations.screenshotFile = null
			localStorage.setItem('orderCustomizations', JSON.stringify(this.orderCustomizations))
			localStorage.setItem('orderOptions', JSON.stringify(this.orderOptions))

			this.backToCustomizationPage()

			window.postMessage("logging_out_keep_session")
			window.parent.postMessage("logging_out_keep_session")

			this.$auth.logout({
				makeRequest: true,
				data: {},
				redirect: '/login-consumer',
			});
		},
		toggleInspector(){
			this.getSeatLoader().toggleInspector()
			this.hide = !this.hide
		},
		nextSocket(isIncrease){
			this.getSeatLoader().zoomToSocket(null, null, isIncrease)
		},
		resetPartSpecs()
		{
			let part_specs = this.customizedPartSpecs

			for(let [index, part_spec] of part_specs.entries())
			{
				let temp = {}

				temp = {
					material_no: part_spec.material_no,
					material_no_web: part_spec.material_no_web,
					name: part_spec.name,
					part: part_spec.part,
					color: part_spec.color
				}

				part_spec = temp

				Vue.set(this.customizedPartSpecs, index, part_spec)
			}
		},
		async restoreCustomization()
		{
			let isCustomizing = localStorage.getItem('isCustomizating')
			if(isCustomizing)
			{
				this.restoreCustomizationInfo()
			}

			if(this.orderCustomizations.package_id)
			{
				await this.selectedPackage(this.packageSelections.find(item => item.value == this.orderCustomizations.package_id))

				if(this.orderCustomizations.seam)
				{
					let seam = this.orderCustomizations.seam
					this.selectedSeam(seam, seam.seamColor, seam.pipingColor)
				}
				
				if(this.orderCustomizations.mainColor || this.orderCustomizations.secondaryColor)
				{
					await this.selectMainColor(this.orderCustomizations.mainColor)
					this.selectSecondaryColor(this.orderCustomizations.secondaryColor)
				}

				if(this.partSpecs)
				{
					await this.restorePartSpecsCustomization()
				}

				if(this.orderCustomizations.logo)
				{
					let selected_part = await this.customizedPartSpecs.find(i => (i.part.socketInfo.is_logo == 1 && !i.psp && !i.perforation && !i.special_material && !i.digital_punching && !i.pattern_stitching) )
					let related_mesh = selected_part?.part
					let logo = this.orderCustomizations.logo
					let path = this.getLogoImage({}, logo.file_uploads)
					this.selectLogo(path, logo, related_mesh, selected_part)
				}
			}
			else
			{
				await this.selectedPackage(this.packageSelections[0])
			}
		},
		async restorePartSpecsCustomization()
		{
			for(let part_spec of this.partSpecs)
			{
				let related_mesh = await this.customizedPartSpecs.find(i => i.material_no == part_spec.material_no)?.part
				part_spec.part = related_mesh
				part_spec.color.rgba = this.computedRGBA(part_spec.color)
				
				if(!part_spec.special_material)
				{
					await this.getSeatLoader().setPartColor(part_spec.color, related_mesh)
				}
				else
				{
					await this.selectSpecialMaterial(part_spec, part_spec.special_material.mask_url)
				}
				
				if(part_spec.perforation)
				{
					let perforation = part_spec.perforation
					await this.selectPerforation(part_spec, perforation.mask_url, part_spec.part)
				}
				else if(part_spec.psp)
				{
					let psp = part_spec.psp
					let color = [psp.color.r, psp.color.g, psp.color.a]
					await this.selectPsp(part_spec, psp.psp_details.mask_url, color, part_spec.part)
				}

				if(part_spec.digital_punching)
				{
					let digital_punching = part_spec.digital_punching
					//let color = [digital_punching.color.r, digital_punching.color.g, digital_punching.color.a]
					await this.selectDigitalPunching(part_spec, digital_punching.digital_punching_details.mask_url, [], part_spec.part)
				}
				else if(part_spec.pattern_stitching)
				{
					let pattern_stitching = part_spec.pattern_stitching
					let color = [pattern_stitching.color.r, pattern_stitching.color.g, pattern_stitching.color.a]
					await this.selectPatternStitching(part_spec, pattern_stitching.pattern_stitching_details.mask_url, color, part_spec.part)
				}
			}
			this.customizedPartSpecs = this.partSpecs
		},
		async restoreCustomizationInfo()
		{
			let orderCustomizations = JSON.parse(localStorage.getItem("orderCustomizations"))
			let orderOptions = JSON.parse(localStorage.getItem("orderOptions"))
			let i = 1;
			let partSpecs = []

			while(i<=100)
			{
				let part_spec = JSON.parse(localStorage.getItem("partSpecs_"+i))
				if(!part_spec)
					break;

				partSpecs.push(part_spec)
				localStorage.removeItem("partSpecs_"+i)
				i++;
			}

			if(orderCustomizations && orderOptions && partSpecs)
			{
				localStorage.removeItem("orderCustomizations")
				localStorage.removeItem("orderOptions")
				localStorage.removeItem("isCustomizating")
				
				this.setOrderCustomizations(orderCustomizations)
				// this.setOrderOptions(orderOptions)
				// this.setPartSpecs(partSpecs)
			}

			this.setRedirect(false)
		},
		cancelZoom()
		{
			if(this.slider == UPLOAD_LOGO_PAGE_INDEX)
				this.cancelLogoableZoom()
			else
				this.cancelZoomPart()
		}
	}
}
</script>
<style scoped>
	#seamWindow {
		width: 301px;
		height: 301px;
	}
	.highlight{
		border: 5px solid #fcde0a;
	}
	.checkout{
		z-index: 20;
		position: absolute;
		height: 4.2em !important;
		width: 8.5em;
		top: -4.2em;
		right: 0px;
	}
	body, html {
		padding: 0;
		margin: 0;
		width: 100%;
		min-height: 100vh;
	}
</style>